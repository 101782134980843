import React, {useEffect} from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputSelect, FormInputDate
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, ChannelSelect, AgentSelect, FileUploadInput, LocalCache
} from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {NotePlainViewer, NotesEditor} from "./Notes";
import {ContractStateSelect} from "./ContractStates";
import {SellingPointSelect} from "./SellingPoints";
import {TechAssistanceStateSelect} from "./TechAssistanceStates";
import {showExportModal} from "./Exports";
const stateTag = state=>{

  if(!state) return '/';

  let color = state.color;

  if(!color)
    color = '#eee';

  return <span style={{backgroundColor: color,padding:'5px',borderRadius:'5px',color:'#fff'}}>{state.name}</span>
}

function TechAssistanceTypeSelect( props ){
  return <FormInputSelect label="Tipo Scheda" value={props.value}
                          values={{
                            '0': 'Tutte',
                            formAssistenza: 'Scheda Assistenza',
                            formAssistenzaFibra: 'Scheda Assistenza Fibra'
                          }}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={props.onChange} />
}

function TechAssistanceUnconfirmedCountBadge(){

  const [ count , setCount ] = React.useState(-1); // -1 = Loading

  const updateCount = () => {
    fetch( 'api' , 'TechAssistance' , 'info' , { filters: { filterCheck: 1 } } ).then( msg => {
      setCount( msg.body.count );

      setTimeout( updateCount ,
        window.location.pathname.indexOf('tech_assistance') >= 0 ? 1000 * 3 : 1000 * 60 * 10
      );
    } );
  };

  useEffect( () => {
    updateCount();
  } , [] );


  return <>
    { count > 0 && <span className="badge badge-warning">{count > 99 ? "99+" : count }</span> }
  </>
}

class TechAssistance extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false,

      filterType: 0,
      filterArea: 'Tutte',
      filterState: 0,
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
      filterCheck: '-1',

      checkTableUpdate: 0,

      showConfirmAll: false
    };

  }

  componentDidMount(){
    this.getFormStates();
    this.updateTree();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevState.filterType != this.state.filterType ) {
      this.updateTree();
      //this.getFormStates();
    }

  }

  updateTree(){

    if(this.state.filterType===0){
      return this.setState({tree: {'Tutte':[]}});
    }

    fetch( 'api' , 'TechAssistance' , 'areas', this.state.filterType ).then( msg => {
      this.setState({ tree: {...{'Tutte': []},...msg.body} });
    });
  }

  getFilters() {

    return {
      type: this.state.filterType,
      area: this.state.filterArea,
      TargetUserId: this.state.filterTargetUser ? this.state.filterTargetUser.id : null,
      AuthorUserId: this.state.filterAuthorUser ? this.state.filterAuthorUser.id : null,
      TechAssistanceStateId: this.state.filterState ? this.state.filterState.id : 0,
      dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
      dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
      check: this.state.filterCheck
    };
  }


  getCheckFilters() {
    return {
      filterCheck: 1,
      checkTableUpdate : this.state.checkTableUpdate,
      type: this.state.checkFilterType,
    };
  }

  getFormStates(){
    fetch( 'api' , 'TechAssistanceStates' , 'list' ).then( msg => {

      let states = {0:'Tutti'};

      for(const[key,value] of Object.entries(msg.body)){
        states[key] = value.name;
      }

      this.setState({ stateList: states});
    });
  }

  onCheck( id ){
    fetch( 'api' , 'TechAssistance' , 'setCheck', id, 1 ).then( msg => {
      this.setState({ checkTableUpdate: this.state.checkTableUpdate+1})
    });

  }

  render(){

    const columns = {
      id: '#',
      birth: 'Creazione',
      update: 'Ult. aggiornamento',
      type: 'Scheda',
      area: 'Area',
      sep: 'SEP',
      state: 'Stato',
      actions: '',
    };


    const toType = function( type ){
      let types = NewTechAssistanceModal.getTypes();
      return types[type];
    }

    const row = item => {
      return <tr>
        <td>{item.id}</td>
        <td>{formatLeadName( item.Author )} il {moment(item.createdAt).format('D MMMM YY HH:mm')}</td>
        <td>{formatLeadName(item.LastUpdateUser)} il {moment(item.updatedAt).format('D MMMM YY HH:mm')}</td>
        <td>{toType(item.type)}</td>
        <td>{item.area} / {item.subarea}</td>
        <td>{item.SellingPoint ? item.SellingPoint.name : '-'}</td>
        <td>{stateTag(item.TechAssistanceState)}</td>
        <td>
          <div class="btn-group">
            {can('TechAssistance.save') && <button class="btn-white btn btn-xs mr-2" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: item.id});
            }}>Vedi</button> }
            {can('TechAssistance.history') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({historyModal: item.id});
            }}>Storico</button> }
          </div>
        </td>
      </tr>
    };

    const columnsCheck = {
      id: '#',
      check: '',
      birth: 'Creazione',
      update: 'Ult. aggiornamento',
      type: 'Scheda',
      area: 'Area',
      sep: 'SEP',
      state: 'Stato',
      actions: '',
    };


    const rowCheck = item => {
      return <tr>
        <td>{item.id}</td>
        <td><button className={"btn btn-primary btn-sm"} onClick={se=>{
          se.preventDefault();
          this.onCheck( item.id );
        }}>Conferma</button></td>
        <td>{formatLeadName( item.Author )} il {moment(item.createdAt).format('D MMMM YY HH:mm')}</td>
        <td>{formatLeadName(item.LastUpdateUser)} il {moment(item.updatedAt).format('D MMMM YY HH:mm')}</td>
        <td>{toType(item.type)}</td>
        <td>{item.area} / {item.subarea}</td>
        <td>{item.SellingPoint ? item.SellingPoint.name : '-'}</td>
        <td>{stateTag(item.TechAssistanceState)}</td>
        <td>
          <div class="btn-group">
            {can('TechAssistance.save') && <button class="btn-white btn btn-xs mr-2" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: item.id});
            }}>Vedi</button> }
            {can('TechAssistance.history') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({historyModal: item.id});
            }}>Storico</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Schede assistenza tecnica" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          <div className={'col-12'}>
          { can( 'TechAssistance.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({sellingPointModal: true});
          }}>Nuova scheda</button>}
          </div>
        </div>

        <div className="row">

          { this.state.showConfirmAll && <Modal onClose={se=>this.setState({showConfirmAll: false})}>
            <h4>Conferma operazione</h4>
            <p>Sei sicuro di voler conferma tutti i messaggi?</p>
            <a href="#" className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              fetch( 'api' , 'TechAssistance' , 'checkAll', { filters: this.getCheckFilters() } ).then( msg => {
                this.setState({ showConfirmAll: false });
                window.easylifeBgTasksUpdate();
              });
            }}>Conferma</a>
            <a href="#" className="btn btn-secondary ml-2" onClick={se=>{
              se.preventDefault();
              this.setState({showConfirmAll: false});
            }}>Annulla</a>
          </Modal>}

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className={"ibox-title"}><h5>Da controllare</h5></div>
                  <div class="ibox-tools">
                    <a href="#" className="btn btn-xs btn-primary mr-2" onClick={ se => {
                      se.preventDefault();
                      showExportModal( 'TechAssistance' , this.getCheckFilters() );
                    }}>Esporta</a>
                    <a href="#" className="btn btn-xs btn-danger mr-2" onClick={ se => {
                      se.preventDefault();
                      this.setState({showConfirmAll: true})
                    }}>Conferma tutto</a>
                  </div>
                  <div className="ibox-content">

                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <TechAssistanceTypeSelect value={this.state.checkFilterType}
                                                  onChange={se=>this.setState({checkFilterType: se})} />
                      </div>
                    </div>

                    <AbstractTableViewer model="TechAssistance"
                                         filters={this.getCheckFilters()}
                                         row={rowCheck}
                                         columns={columnsCheck}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className={"ibox-title"}><h5>Tutte le schede</h5></div>
                  <div class="ibox-tools">
                    <a href="#" className="btn btn-xs btn-primary mr-2" onClick={ se => {
                      se.preventDefault();
                      showExportModal( 'TechAssistance' , this.getFilters() );
                    }}>Esporta</a>
                  </div>
                  <div className="ibox-content">

                    <div className="row">

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <UserSelect value={this.state.filterAuthorUser}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Autore"
                                    onChange={se=>this.setState({ filterAuthorUser: se , filterAuthorUserId: se ? se.id : null })} />
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <TechAssistanceTypeSelect value={this.state.filterType}
                                                  onChange={se=>this.setState({filterType: se})} />
                      </div>



                      {this.state.tree && <>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                          <FormInputSelect label="Macro area" value={this.state.filterArea}
                                           values={Object.fromEntries(Object.keys(this.state.tree).map( v => {
                                             return [ v,v ];
                                           }) )}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="area"
                                           onChange={se=>this.setState({filterArea: se})} />
                        </div>


                      </>}



                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <FormInputDate value={this.state.filterDateStart}
                                       key="sdate"
                                       label="Data inizio"
                                       direction={FormInputContainer.HORIZONTAL}
                                       onChange={se => this.setState({filterDateStart: se})}/>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <FormInputDate value={this.state.filterDateEnd}
                                       key="edate"
                                       label="Data fine"
                                       direction={FormInputContainer.HORIZONTAL}
                                       onChange={se => this.setState({filterDateEnd: se})}/>
                      </div>

                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                        <TechAssistanceStateSelect value={this.state.filterState}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se=>this.setState({ filterState: se })} />
                      </div>

                    </div>

                    <AbstractTableViewer model="TechAssistance"
                                         filters={this.getFilters()}
                                         row={row}
                                         columns={columns}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        {this.state.sellingPointModal && <NewTechAssistanceModal onDone={res=>{
          this.setState({sellingPointModal: false});
        }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}


        {this.state.historyModal && <HistoryTechAssistanceModal onDone={res=>{
          this.setState({historyModal: false});
        }} id={this.state.historyModal === true ? false: this.state.historyModal} />}

      </div>];
  }
}



class NewTechAssistanceModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      Notes: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  updateTree(){
    this.setState({tree: false });
    fetch( 'api' , 'TechAssistance' , 'areas', this.state.type ).then( msg => {
      this.setState({ tree: msg.body  });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevState.type != this.state.type ) {
      this.setState({ area: null , subArea: null });
      this.updateTree();
    }
  }

  componentDidMount(){
    this.setState({tree: false});

    const cacheSep = LocalCache.get( 'loginSEP' );

    this.setState({
      SellingPointId: (cacheSep?cacheSep.id:false),
      SellingPoint: (cacheSep?cacheSep:false),
    });

    apiFormInit( 'TechAssistance', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'TechAssistance', this)
  }

  static getTypes(){
    let types = {};

    let permissions = {
      formAssistenza: 'Scheda Assistenza',
      formAssistenzaFibra: 'Scheda Assistenza Fibra'
    };

    Object.keys( permissions ).filter( perm => {
      return can( 'TechAssistance.' + perm );
    }).map( perm => {
      types[perm] = permissions[perm];
    });

    return types;
  }

  render(){

    if (this.state.loading)
      return <Modal>
        <Loader/>
      </Modal>;

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <h2>Scheda N. {this.state.id}</h2>

        <FormInputSelect label="Scheda" value={this.state.type}
                         values={NewTechAssistanceModal.getTypes()}
                         direction={FormInputContainer.HORIZONTAL}
                         onChange={se=>this.setState({type: se})} />

        {this.state.tree && <>
          <FormInputSelect label="Macro area" value={this.state.area}
                         values={Object.fromEntries(Object.keys(this.state.tree).map( v => {
                           return [ v,v ];
                         }) )}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="area"
                         onChange={se=>this.setState({area: se})} />

          { ( this.state.tree[this.state.area] && this.state.tree[this.state.area].length )  ?
          <FormInputSelect label="Area" value={this.state.subarea}
                         values={Object.fromEntries(this.state.tree[this.state.area].map( v => {
                           return [ v , v ];
                         }))}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="subarea"
                         onChange={se=>this.setState({subarea: se})} /> : null}

        </>}
        
        <FormDashline />

        <TechAssistanceStateSelect value={this.state.TechAssistanceState}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setState({ TechAssistanceState: se, TechAssistanceStateId:  se ? se.id : null })} />

        <FormDashline />

        <SellingPointSelect value={this.state.SellingPoint}
                            direction={FormInputContainer.HORIZONTAL}
                            label="SEP"
                            onChange={se => this.setState({SellingPoint: se, SellingPointId: se ? se.id : null})}/>

        {can( 'TechAssistance.involveUsers') &&
          <UserSelect value={this.state.InvolvedUsers}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Utenti coinvolti" isMulti
                      onChange={se=>this.setState({ InvolvedUsers: se })} />}

        <NotesEditor notes={this.state.Notes} onChange={ notes => {
          this.setState({Notes: notes})
        }} name={"TechAssistance"} />

        <FormDashline />

        <label>Allegati</label>
        <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                         filesId={this.state.filesId} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva scheda"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class HistoryTechAssistanceModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {

    };

    //this.onSubmit = this.onSubmit.bind(this);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    // if( prevState.type != this.state.type ) {
    //
    // }

  }

  componentDidMount(){
    apiFormInit( 'TechAssistance', this );
  }


  render(){

    if (this.state.loading)
      return <Modal>
        <Loader/>
      </Modal>;

    return <Modal onClose={this.props.onDone}>



      {this.state.History && this.state.History.map(h=>{
        return <>
        <p>Stato cambiato in {stateTag(h.State)} da <b>{h.User.name} {h.User.surname}</b> il {moment(h.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p><hr/>
      </>
      })}


        <AbstractApiFormFooter {...this.state} />


    </Modal>;
  }

}


class TechAssistanceCheckCheckbox extends React.Component{
  constructor(props) {
    super();

    this.state = {
      id: props.form.id,
      check: props.form.check,
      loading: true,
    };


    this.setCheck = this.setCheck.bind(this);

  }

  componentDidMount(props) {
    //this.setState({})
    this.getFormChecks();
  }


  getFormChecks(){
    fetch( 'api' , 'TechAssistance' , 'checks', this.state.id ).then( msg => {
      this.setState({ check: msg.body, loading: false  });
    });

  }


  setCheck(){
    fetch( 'api' , 'TechAssistance' , 'setCheck', this.state.id, !this.state.check ? 1 : 0 ).then( msg => {
      this.setState({ check: !this.state.check  });
      if( this.props.onCheck )
        this.props.onCheck( this.state.id );
    });
  }


  render() {


    return <>
        {!this.state.loading && <FormInputRadio label={this.state.check ? "Revisionato" : "Da Revisionare"} checked={this.state.check} onClick={this.setCheck}
                        direction={FormInputContainer.HORIZONTAL}/>}
        </>;
  }


}

export { TechAssistance , NewTechAssistanceModal, HistoryTechAssistanceModal, TechAssistanceCheckCheckbox, TechAssistanceUnconfirmedCountBadge };