import React from 'react';
import './App.css';
import moment from 'moment';

import {Bar, ResponsiveBar} from '@nivo/bar';
import {ResponsivePie} from '@nivo/pie';
import {ResponsiveSankey} from '@nivo/sankey';

import {Link} from "react-router-dom";

import {ChannelCodeSelect} from './ChannelCodes';

import {
    Loader, fetch, FormDashline, FormInputTime, fetchHook,
    FormInputText, FormButtons, FormInputContainer, FormInputRadio,
    Modal, FormInputSelect, FormInputDate, FormInputMonth,
    BoxContainer, Row, FormInputYear, PaginationButtons,
    FormInputColorSimple, MenuButton
} from './Common.js';

import {AddressData, formatLeadName} from './Leads';

import {RoleSelect} from './Roles.js';

import {
    ChannelDescription, ChannelSelect, ContractTypesSelect, Breadcumbs,
    AbstractTableViewer, can, AbstractApiFormFooter, apiFormInit, apiFormSave,
    AgentSelect, formatEur, FileUploadInput, ApiSelect, ApiMultiSelect, Pagination, EleGasSelect, ConfirmableButton
} from './AppCommon';

import {ProductSelect, SubProductSelect, AdvancedProductSelect} from './Products';
import {ContractStateSelect, ContractStateViewer} from './ContractStates';
import {SellingPointSelect} from './SellingPoints';
import {UserSelect} from './Users';
import {ContractVoidCauseSelect, ContractVoidStateViewer} from './ContractVoidCauses';
import {PieChart} from "./SimpleChats";
import {FormInputRange} from "./Common";
import {ChannelVerificationImportResultsModal} from "./ChannelVerification";
import {NotePlainViewer, NotesEditor} from "./Notes";
import {TagsSelect} from "./Tags";
import {EnelxSellsStateViewer} from "./EnelxSellsStates";
import {showExportModal} from "./Exports";
import {getOperationLabel} from "./Practices";

function DocumentoIdentitaSelect(props) {
    const disabled = props.disabled ? {disabled: true} : {};

    return <FormInputSelect values={{
        'Carta identità': 'Carta identità',
        'Patente': 'Patente',
        'Passaporto': 'Passaporto',
        'Altro': 'Altro'
    }}
                            label="Documenti identità caricato" {...disabled}
                            direction={FormInputContainer.HORIZONTAL}
                            {...props}
    ></FormInputSelect>
}

class FormIbanInput extends React.Component {
    render() {
        let validation = false;

        /*if( this.props.value ){
      if( !this.props.value.match( /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/g ) ){
        validation = {
          fieldName: 'iban',
          message: 'Iban non valido'
        }
      }
    }*/

        return <FormInputText upperCase={true} label="IBAN"
                              direction={FormInputContainer.HORIZONTAL}
                              validation={validation} name="iban"
                              {...this.props} />
    }
}


class ContractsTableStandard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: {
                id: 'ID Contratto',
                channel: 'Canale',
                agenti: 'Caricato Da',
                client: 'Cliente',
                createdAt: this.props.filterStateDateType == 'state' ? 'Data ins. sistema' : 'Data inserimento',
                updatedAt: 'Ultima modifica',
                state: 'Stato',
                actions: 'Azioni',
            },
            row: contract => {
                return <tr key={contract.id}>
                    <td>{contract.uniqueUuid} {contract.isTp2 && <ContractTp2TelephoneViewer number={contract.Lead_telephone}/>}</td>
                    <td>{contract.Channel_name.trim().length ? contract.Channel_name : '-'}</td>
                    <td>{contract.Agent_name_surname.trim().length ? contract.Agent_name_surname : '-'}</td>
                    <td><Link to={'/leads_list/detail/' + contract.LeadId}><span>{contract.Lead_name_surname}</span></Link></td>
                    <td>{moment(contract.createdAt).format('D MMMM YY HH:mm')}</td>
                    <td>{moment(contract.updatedAt).format('D MMMM YY HH:mm')}</td>
                    <td><ContractStateViewer contract={{state: contract.state, ContractState: {type: contract.ContractState_type}}}/></td>
                    <td>
                        <div className="btn-group">
                            <button className="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                props.statePropagator({contractModal: contract.id});
                            }}>Vedi
                            </button>

                            {can('Contracts.history') && <button className="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                props.statePropagator({historyModal: contract.id});
                            }}>Storico</button>}

                            {can('MarketingReports.agentReportAll') && <Link className="btn-white btn btn-xs"
                                                                             to={"/marketing_plans_report_contract?contractId=" + contract.id}>Reports</Link>}

                        </div>
                    </td>

                </tr>
            }
        }
    }



    render() {

        return (<AbstractTableViewer model="ContractsExploded"
                                     filters={this.props.getFilters}
                                     row={this.state.row}
                                     columns={this.state.columns}/>);

    }


}

class ContractsTableNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: {
                id: 'ID Contratto',
                channel: 'Canale',
                state: 'Stato',
                substate: 'Sotto Stato',
                note: 'Note Contratto',

                actions: 'Azioni',
            },
            row: (contract) => {
                return <tr key={contract.id}>
                    <td>{contract.uniqueUuid}</td>
                    <td>{contract.Channel_name.trim().length ? contract.Channel_name : '-'}</td>

                    <td><ContractStateViewer contract={{state: contract.state, ContractState: {type: contract.ContractState_type}}}/></td>
                    <td><ContractVoidStateViewer ids={contract.ContractVoidCauses_Ids ? contract.ContractVoidCauses_Ids : null}/></td>
                    <td><NotePlainViewer notes={contract.Notes}/></td>
                    <td>
                        <div className="btn-group">
                            <button className="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                props.statePropagator({contractModal: contract.id});
                                console.log('VEDI');
                                //this.setState({contractModal: contract.id});
                            }}>Vedi
                            </button>

                            {can('Contracts.history') && <button className="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                console.log('VEDI');
                                props.statePropagator({historyModal: contract.id});
                                //this.setState({historyModal: contract.id});
                            }}>Storico</button>}
                        </div>
                    </td>

                </tr>
            }
        }
    }


    render() {

        return (<AbstractTableViewer model="ContractsExploded"
                                     filters={this.props.getFilters}
                                     row={this.state.row}
                                     columns={this.state.columns}/>);

    }


}


class ContractsTableRoles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            defaultRoles: [],
            loading: true,
            columns: {
                id: 'ID Contratto',
                channel: 'Canale',
                state: 'Stato',
                roles: 'Ruoli',

                actions: 'Azioni',
            },
            row: null
        }

        this.loadRoles = this.loadRoles.bind(this);
    }

    componentDidMount() {

        this.loadRoles();

    }

    loadRoles() {

        var props = this.props;
        fetch('api', 'ContractTypeRoles', 'select',
            {filters: {ContractTypeId: this.props.ContractTypeId}}).then(msg => {

            this.setState({
                defaultRoles: msg.body,
                loading: false,
                row: (contract) => {
                    return <tr key={contract.id}>
                        <td>{contract.uniqueUuid}</td>
                        <td>{contract.Channel_name.trim().length ? contract.Channel_name : '-'}</td>

                        <td><ContractStateViewer contract={{state: contract.state, ContractState: {type: contract.ContractState_type}}}/></td>
                        <td><ContractRolesViewer defaultRoles={msg.body} contract={contract}/></td>
                        <td>
                            <div className="btn-group">
                                <button className="btn-white btn btn-xs" onClick={se => {
                                    se.preventDefault();
                                    props.statePropagator({contractModal: contract.id});
                                    console.log('VEDI');
                                    //this.setState({contractModal: contract.id});
                                }}>Vedi
                                </button>

                                {can('Contracts.history') && <button className="btn-white btn btn-xs" onClick={se => {
                                    se.preventDefault();
                                    console.log('VEDI');
                                    props.statePropagator({historyModal: contract.id});
                                    //this.setState({historyModal: contract.id});
                                }}>Storico</button>}
                            </div>
                        </td>

                    </tr>
                }
            });

        });
    }


    render() {

        return (
            <>{this.state.loading ? <Loader/> : <AbstractTableViewer model="ContractsExploded"
                                                                     filters={this.props.getFilters}
                                                                     row={this.state.row}
                                                                     columns={this.state.columns}/>}
            </>
        );

    }


}

class Contracts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contractModal: false,
            historyModal: false,
            importModal: false,
            filterUniqueUuid: '',
            filterStateContract: 0,
            filterChannel: 0,
            filterChannelCode: -1,
            filterSellingPoint: 0,
            filterDateStart: moment().startOf('year'),
            filterRoleUsers: 0,
            filterStateDateType: 'created',
            filterDateEnd: moment(),
            filterAgent: 0,
            filterStateType: 0,
            filterFreeMarket: '',
            filterTp2: 'all',
            filterNoReportProduction: '',
            tableView: 1
        };

    }


    render() {


        return [<Breadcumbs title="Contratti" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row" style={{marginBottom: '15px'}}>
                {can('Contracts.save') && <button className="btn btn-primary" onClick={se => {
                    se.preventDefault();
                    this.setState({contractModal: true});
                }}>Nuovo contratto</button>}

                {can('Contracts.import') && <button className="btn btn-primary" onClick={se => {
                    se.preventDefault();
                    this.setState({importModal: true});
                }} style={{marginLeft: '10px'}}>Importa file</button>}

                {can('Contracts.import') && <button className="btn btn-primary" onClick={se => {
                    se.preventDefault();
                    this.setState({forceStateModal: true});
                }} style={{marginLeft: '10px'}}>Forza stati da file</button>}

                {can('Contracts.export') && <button className="btn btn-primary" onClick={se => {
                    se.preventDefault();
                    showExportModal( 'ContractsExploded' , this.getFilters() );
                }} style={{marginLeft: '10px'}}>{this.state.exporting ? 'In corso (Clicca per aggiornare)' : 'Esporta Excel'}</button>}

            </div>

            {this.state.historyModal && <ContractsHistoryModal onDone={res => {
                this.setState({historyModal: false});
            }} id={this.state.historyModal}/>}

            {this.state.importModal && <ImportContractsModal onDone={res => {
                this.setState({importModal: false});
            }}/>}

            {this.state.forceStateModal && <ForceStateModal onDone={res => {
                this.setState({forceStateModal: false});
            }}/>}

            {this.state.contractModal && <NewContractModal onDone={res => {
                this.setState({contractModal: false});
            }} id={this.state.contractModal === true ? false : this.state.contractModal}/>}

            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-lg-2">
                                    <FormInputText label="ID Contratto" value={this.state.filterUniqueUuid}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterUniqueUuid: se.target.value})}/>
                                </div>

                                <div className="col-lg-4">
                                    <ContractStateSelect value={this.state.filterStateContract}
                                                         direction={FormInputContainer.HORIZONTAL}
                                                         all={true}
                                                         isMulti
                                                         onChange={se => this.setState({filterStateContract: se ? (se.length > 0 ? se : false) : false})}/>
                                </div>

                                <div className="col-lg-2">
                                    <ChannelSelect value={this.state.filterChannel}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   all={true}
                                                   isMulti
                                                   additionalValues={[{id: -1, Brand: {name: ''}, name: 'Senza canale'}]}
                                                   onChange={se => this.setState({filterChannel: se})}/>
                                </div>

                                {can('Contracts.filterAgentId') && <div className="col-lg-4">
                                    <AgentSelect value={this.state.filterAgent}
                                                 label="Caricato da"
                                                 direction={FormInputContainer.HORIZONTAL}
                                                 all={true}
                                                 onChange={se => this.setState({filterAgent: se})}/>
                                </div>}

                                <div className="col-lg-2">
                                    <FormInputSelect label="Tipo date" value={this.state.filterStateDateType}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterStateType"
                                                     values={{
                                                         'state': 'Inserito a sistema',
                                                         'created': 'Creazione',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterStateDateType: se})
                                                     }}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputDate value={this.state.filterDateStart}
                                                   key="sdate"
                                                   label="Data inizio"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateStart: se})}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputDate value={this.state.filterDateEnd}
                                                   key="edate"
                                                   label="Data fine"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateEnd: se})}/>
                                </div>

                                <div className="col-lg-4">
                                    <SellingPointSelect value={this.state.filterSellingPoint}
                                                        direction={FormInputContainer.HORIZONTAL}
                                                        onChange={se => this.setState({filterSellingPoint: se})}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterStateType"
                                                     values={{
                                                         '': 'Tutti',
                                                         'positive': 'Pagabile',
                                                         'no': 'Neutro',
                                                         'negative': 'Non Pagabile',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterStateType: se})
                                                     }}/>
                                </div>

                                <div className="col-xl-2 col-lg-4">
                                    <FormInputRange label="Kw" value={this.state.filterKw}
                                                    direction={FormInputContainer.HORIZONTAL}
                                                    name="filterKw"
                                                    onChange={se => {
                                                        this.setState({filterKw: se})
                                                    }}/>
                                </div>

                                <div className="col-lg-2">
                                    <ContractServiceType value={this.state.filterServiceType}
                                                         values={{
                                                             '': 'Tutti',
                                                             'single': 'Singolo',
                                                             'multiEle': 'Multi ELE',
                                                             'dual': 'Dual',
                                                         }}
                                                         onChange={se => {
                                                             this.setState({filterServiceType: se})
                                                         }}/>
                                </div>

                                <div className="col-lg-4">
                                    <ContractVoidCauseSelect value={this.state.filterVoidCause}
                                                             direction={FormInputContainer.HORIZONTAL}
                                                             additionalValues={[{id: -1, name: 'Senza causale'}]}
                                                             onChange={se => {
                                                                 this.setState({filterVoidCause: se})
                                                             }}/>
                                </div>

                                {can('Contracts.filterUserRoles') && <div className="col-lg-4">
                                    <UserSelect value={this.state.filterRoleUsers}
                                                direction={FormInputContainer.HORIZONTAL}
                                                label="Utenti provvigionati"
                                                onlyNetwork={true}
                                                isMulti
                                                all={true}
                                                onChange={se => this.setState({
                                                    filterRoleUsers: se
                                                })}/>
                                </div>}

                                {can('Contracts.filterUserRoles') && <div className="col-lg-3">
                                    <FormInputSelect label="Provvigioni" value={this.state.filterUserRoles_NoUsers}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterUserRoles_NoUsers"
                                                     values={{
                                                         '': 'Tutti',
                                                         'no_user': 'Senza utenti assegnati',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterUserRoles_NoUsers: se})
                                                     }}/>
                                </div>}

                                {can('Contracts.filterProducts') && <div className="col-lg-4">
                                    <ProductSelect value={this.state.filterProducts}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   hideCategories={true}
                                                   isMulti
                                                   all={true}
                                                   onChange={se => this.setState({
                                                       filterProducts: se
                                                   })}/>
                                </div>}

                                <div className={"col-md-2"}>
                                    <EleGasSelect value={this.state.filterEleGas}
                                                  onChange={se => {
                                                      this.setState({filterEleGas: se})
                                                  }} />
                                </div>

                                <div className={"col-md-2"}>
                                    <FormInputSelect label="Tipo Mercato" value={this.state.filterFreeMarket}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterFreeMarket"
                                                     values={{
                                                         '': 'Tutti',
                                                         '1': 'Libero',
                                                         '0': 'Tutelato',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterFreeMarket: se})
                                                     }}/>
                                </div>

                                <div className={"col-md-2"}>
                                    <FormInputSelect label="Tp2" value={this.state.filterTp2}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterTp2"
                                                     values={{
                                                         'all': 'Tutti',
                                                         '1': 'Si',
                                                         '0': 'No',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterTp2: se})
                                                     }}/>
                                </div>

                                <div className="col-lg-4">
                                    <FormInputText
                                        onChange={se => {
                                            this.setState({filterName: se.target.value})
                                        }}
                                        direction={FormInputContainer.HORIZONTAL}
                                        label="Nome lead"
                                        value={this.state.filterName}/>
                                </div>

                                <div className="col-lg-2">
                                    <ChannelCodeSelect value={this.state.filterChannelCode}
                                                       direction={FormInputContainer.HORIZONTAL}
                                                       all={true}
                                                       isMulti
                                                       additionalValues={[{id: -1, name: 'Tutti'}]}
                                                       onChange={se => this.setState({filterChannelCode: se})}/>
                                </div>
                                
                                <div className="col-lg-2 col-md-4">
                                    <ContractTypesSelect
                                        onChange={se => {
                                            this.setState({filterContractType: se})
                                        }} direction={FormInputContainer.HORIZONTAL}
                                        label={"Tipo contratto"}
                                        value={this.state.filterContractType}/>
                                </div>

                                <div className={"col-md-2"}>
                                    <FormInputSelect label="Visibilità report" value={this.state.filterNoReportProduction}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="filterNoReportProduction"
                                                     values={{
                                                         '': 'Tutti',
                                                         '0': 'Solo visibili',
                                                         '1': 'Solo NON visibili',
                                                     }}
                                                     onChange={se => {
                                                         this.setState({filterNoReportProduction: se})
                                                     }}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {this.state.loading ? <Loader/> : <div className="row">


                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <div className={'row'}>
                                <div className={'col-12 col-lg-4'}>
                                    <FormInputSelect label="Visualizzazione" value={this.state.tableView}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     name="tableView"
                                                     values={{
                                                         1: 'Standard',
                                                         2: 'Note',
                                                         3: 'Ruoli'

                                                     }}
                                                     onChange={se => {
                                                         this.setState({tableView: se})
                                                     }}/>
                                </div>
                            </div>

                            {this.state.tableView == "1" ?
                                <ContractsTableStandard
                                    statePropagator={(state) => {
                                        this.setState(state)
                                    }}
                                    getFilters={this.getFilters()}
                                    filterStateDateType={this.state.filterStateDateType}
                                />
                                :
                                this.state.tableView == "2" ?
                                    <ContractsTableNotes
                                        statePropagator={(state) => {
                                            this.setState(state)
                                        }}
                                        getFilters={this.getFilters()}
                                        filterStateDateType={this.state.filterStateDateType}
                                    />
                                    :
                                    <ContractsTableRoles
                                        statePropagator={(state) => {
                                            this.setState(state)
                                        }}
                                        getFilters={this.getFilters()}
                                        filterStateDateType={this.state.filterStateDateType}
                                    />
                            }

                        </div>
                    </div>
                </div>
            </div>}


        </div>];
    }

    getFilters() {

        try {
            var m = window.location.search.match(/cacheIds=(.+)/i);
            return {
                cachedIds: m[1]
            };
        } catch (e) {
            console.log(e);
        }

        return {
            uniqueUuid: this.state.filterUniqueUuid,
            AgentId: this.state.filterAgent ? this.state.filterAgent.id : null,
            ChannelId: this.state.filterChannel ? this.state.filterChannel : null,
            ChannelCodeId: this.state.filterChannelCode ? this.state.filterChannelCode.id : null,
            SellingPointId: this.state.filterSellingPoint ? this.state.filterSellingPoint.id : null,
            serviceType: this.state.filterServiceType,
            stateType: this.state.filterStateType,
            filterRoleUsers: this.state.filterRoleUsers,
            filterUserRoles_NoUsers: this.state.filterUserRoles_NoUsers,
            filterVoidCause: this.state.filterVoidCause,
            filterProducts: this.state.filterProducts,
            filterName: this.state.filterName,
            dateType: this.state.filterStateDateType,
            kw: this.state.filterKw,
            dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
            dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
            ContractTypeId: this.state.filterContractType ? this.state.filterContractType.id : false,
            ContractStateId: this.state.filterStateContract ?
                this.state.filterStateContract : false,
            freeMarket: this.state.filterFreeMarket,
            noReportProduction: this.state.filterNoReportProduction,
            eleGas: this.state.filterEleGas,
            tp2: this.state.filterTp2,
        };
    }
}

function ContractTypeFactory(props) {

    if (props.type == 'residenziale')
        return <ContractTypeResidenziale {...props} />;

    if (props.type == 'business')
        return <ContractTypeBusiness {...props} />;

    if (props.type == 'corporate')
        return <ContractTypeCorporate {...props} />;

    if (props.type == 'enelx_mod_unico')
        return <ContractTypeEnelXUnico {...props} />;

    if (props.type == 'enelx_corporate')
        return <ContractTypeEnelXCorporate {...props} />;

    return <p className="text-info">Seleziona il tipo contratto</p>;
}

class ContractTypeEnelXCorporate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.id ? true : false,

            ...this.props.values,
        };

    }

    componentDidUpdate(prevPros, prevState) {

        if (JSON.stringify(this.props.values) != JSON.stringify(prevPros.values)) {
            console.log(`Settings ${JSON.stringify(this.props.values)}`);
            this.setState(this.props.values);
        }

        if (JSON.stringify(this.state) != JSON.stringify(prevState) && this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {

        if (this.state.loading)
            return <Loader/>;

        return <React.Fragment>
            <FormInputText upperCase={true} label="Eventuali Kw Impianto"
                           value={this.state.kw}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({kw: se.target.value})}/>

        </React.Fragment>;
    }
}

class ContractTypeEnelXUnico extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: this.props.id ? true : false,

            dat_contatto: moment(),
            dat_sopra: moment(),
            dat_adesione: moment().format('YYYY-MM-DD'),
            dat_ass_spor: moment().format('YYYY-MM-DD'),
            inst_data: moment().format('YYYY-MM-DD'),
            state_sopr: 'Non eseguito',
            dat_stip_contratto: moment(),
            ...this.props.values,
        };

        if (this.props.id)
            this.loadEnelxSell();
    }

    loadEnelxSell() {
        fetch('api', 'EnelxSells', 'getByContract', this.props.id).then(msg => {
            this.setState({
                loading: false,
                EnelxSell: msg.body,
            })
        });
    }

    componentDidUpdate(prevPros, prevState) {

        if (JSON.stringify(this.props.values) != JSON.stringify(prevPros.values)) {
            console.log(`Settings ${JSON.stringify(this.props.values)}`);
            this.setState(this.props.values);
        }

        if (JSON.stringify(this.state) != JSON.stringify(prevState) && this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {

        if (this.state.loading)
            return <Loader/>;

        if (this.state.EnelxSell) {
            return <>
                <h5>Stato Enelx:</h5>
                <p><EnelxSellsStateViewer id={this.state.EnelxSell.EnelxSellsStateId} /></p>

                <h5>Installatore:</h5>
                <p>{this.state.EnelxSell.EnelxInstaller ?
                    this.state.EnelxSell.EnelxInstaller.name : '-'}</p>

                <h5>Contatto:</h5>
                <p>{this.state.EnelxSell.firstContactAt ?
                    'Effettuato il ' + moment(this.state.EnelxSell.firstContactAt).format('DD/MM/YYYY') :
                    'Non Effettuato'}</p>

                <h5>Sopralluogo:</h5>
                <p>{this.state.EnelxSell.onSiteInspectionAt ?
                    'Effettuato il ' + moment(this.state.EnelxSell.onSiteInspectionAt).format('DD/MM/YYYY') :
                    'Non Effettuato'}</p>

                <h5>Pagamento effettuato:</h5>
                <p>{this.state.EnelxSell.paymentDoneAt ?
                    'Effettuato il ' + moment(this.state.EnelxSell.paymentDoneAt).format('DD/MM/YYYY') :
                    'Non Effettuato'}</p>

                <FormInputText upperCase={true} label="Eventuali Kw Impianto"
                               value={this.state.kw}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({kw: se.target.value})}/>

            </>;
        }

        return <React.Fragment>

            <FormInputText upperCase={true} label="Eventuali Kw Impianto"
                           value={this.state.kw}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({kw: se.target.value})}/>

            <FormInputDate value={this.state.dat_stip_contratto}
                           label="Data Stipula Contratto" requiredField={true}
                           direction={FormInputContainer.HORIZONTAL}

                           onChange={se => {
                               this.setState({dat_stip_contratto: se})
                           }}/>

            <FormInputDate value={this.state.dat_contatto}
                           label="Data contatto"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_contatto: se})
                           }}/>

            <FormInputDate value={this.state.dat_sopra}
                           label="Data effettiva sopralluogo"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_sopra: se})
                           }}/>

            <FormInputTime value={this.state.dat_sopra}
                           label="Ora effettiva sopralluogo"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_sopra: se})
                           }}/>

            <FormInputDate value={this.state.dat_ass_spor}
                           label="Data assegnazione sopralluogo"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_ass_spor: se})
                           }}/>

            <FormInputText upperCase={true} label="Note sopralluogo"
                           value={this.state.note_sopr}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({note_sopr: se.target.value})}/>

            <FormInputSelect values={{
                'Positivo': 'Positivo',
                'Ko': 'Ko',
                'Non eseguito': 'Non eseguito',
            }}
                             label="Stato sopralluogo"
                             value={this.state.state_sopr}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({state_sopr: se})
                             }}></FormInputSelect>

            <FormInputDate value={this.state.dat_adesione}
                           label="Data adesione"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_adesione: se})
                           }}/>

            <FormInputText upperCase={true} label="Regione installazione"
                           value={this.state.inst_regione}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({inst_regione: se.target.value})}/>

            <FormInputText upperCase={true} label="Provincia installazione"
                           value={this.state.inst_provincia}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({inst_provincia: se.target.value})}/>

            <FormInputDate value={this.state.inst_data}
                           label="Data installazione"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({inst_data: se})
                           }}/>

            <FormInputText upperCase={true} label="Frazione / Localita"
                           value={this.state.localita}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({localita: se.target.value})}/>

            <FormInputSelect values={{
                'Altre finanziarie': 'Altre finanziarie',
                'Pag. Unica soluzione': 'Pag. Unica soluzione',
                'Canone ENEL (Ele)': 'Canone ENEL (Ele)',
                'Canone ENEL (Gas)': 'Canone ENEL (Gas)',
                'Canone ENEL (Ele+Gas)': 'Canone ENEL (Ele+Gas)',
                'SDD': 'SDD',
            }}
                             label="Modalità pagamento"
                             value={this.state.payment_type}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({payment_type: se})
                             }}></FormInputSelect>

            <FormInputText upperCase={true} label="Titolare del case sopralluogo"
                           value={this.state.tit_case_sopra}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({tit_case_sopra: se.target.value})}/>

            <FormInputSelect values={{
                'Si': 'Si',
                'No': 'No'
            }}
                             label="Chiusura case installazione"
                             value={this.state.chius_case_uinst}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({chius_case_uinst: se})
                             }}></FormInputSelect>

            <FormInputSelect values={{
                'Positivo': 'Positivo',
                'Negativo': 'Negativo'
            }}
                             label="Esito Credit Check"
                             value={this.state.credit_check}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({credit_check: se})
                             }}></FormInputSelect>

            <FormInputSelect values={{
                'Pagato': 'Pagato',
                'Non pagato': 'Non pagato'
            }}
                             label="Stato Pag. Unica soluzione"
                             value={this.state.state_pag_unica}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({state_pag_unica: se})
                             }}></FormInputSelect>

            <FormInputText upperCase={true} label="Causale sospensione"
                           value={this.state.caus_sosps}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({caus_sosps: se.target.value})}/>

            <FormInputText upperCase={true} label="Causale annullamento"
                           value={this.state.caus_annu}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({caus_annu: se.target.value})}/>


        </React.Fragment>;
    }
}

class ContractTypeResidenziale extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            doc_released_date: moment().format('YYYY-MM-DD'),
            dat_stip_contratto: moment(),
            web_payment: false,
            rid: false,
            ...this.props.values,
        };

    }

    componentDidUpdate(prevPros, prevState) {
        if (JSON.stringify(this.props.values) != JSON.stringify(prevPros.values)) {
            console.log(`Settings ${JSON.stringify(this.props.values)}`);
            this.setState(this.props.values);
        }

        if (JSON.stringify(this.state) != JSON.stringify(prevState) && this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {

        return <React.Fragment>

            <FormInputDate value={this.state.dat_stip_contratto}
                           label="Data Stipula Contratto" requiredField={true}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_stip_contratto: se})
                           }}/>


            <DocumentoIdentitaSelect value={this.state.doc_identita}
                                     disabled={this.props.systemInserted}
                                     onChange={se => this.setState({doc_identita: se})}/>

            <FormInputText upperCase={true} label="Numero documento"
                           value={this.state.doc_number} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({doc_number: se.target.value})}/>

            <FormInputText upperCase={true} label="Rilasciato da"
                           value={this.state.doc_released_by}
                           disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({doc_released_by: se.target.value})}/>

            <FormInputDate value={this.state.doc_released_date}
                           label="Data rilascio"
                           disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({doc_released_date: se})
                           }}/>


            <FormInputRadio label="Fornitura / Fattura diverso da residenza" checked={this.state.differAddress}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    differAddress:
                                        this.state.differAddress ? false : true
                                })
                            }}/>


            {this.state.differAddress && <React.Fragment>
                <h3>Indirizzo di fornitura</h3>
                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="fornitura_"
                />

                <h3>Indirizzo di fatturazione</h3>
                <AddressData onChange={state => {
                    this.setState(state)
                }} disabled={this.props.systemInserted}
                             values={this.state} prefix="fatturazione_"
                />
            </React.Fragment>}

            <FormInputSelect values={{
                'Gas': 'Gas',
                'Elettrica': 'Elettrica',
                'Fibra': 'Fibra'
            }}
                             label="Tipo fornitura"
                             value={this.state.type}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({type: se})
                             }}></FormInputSelect>

            <FormInputRadio label="Provenienza mercato libero" checked={this.state.freeMarket}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    freeMarket:
                                        this.state.freeMarket ? false : true
                                })
                            }}/>

            {this.state.type == 'Elettrica' && <React.Fragment>

                <FormInputText upperCase={true} label="Potenza (kW)"
                               value={this.state.kw}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({kw: se.target.value})}/>
            </React.Fragment>
            }

            {this.state.type == 'Gas' && <React.Fragment>

                <FormInputSelect values={{
                    'Riscaldamento': 'Riscaldamento',
                    'Cottura e/o acqua calda': 'Cottura e/o acqua calda'
                }}
                                 label="Profilo di consumo"
                                 value={this.state.profile}
                                 disabled={this.props.systemInserted}
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => {
                                     this.setState({profile: se})
                                 }}/>

            </React.Fragment>
            }

            <FormInputSelect values={{
                'Bollettino postale': 'Bollettino postale',
                'Addebbito diretto su CC SDD': 'Addebbito diretto su CC SDD'
            }}
                             label="Modalità di pagamento"
                             value={this.state.mod_pagamento}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 let nState = {mod_pagamento: se};
                                 if (se === 'Addebbito diretto su CC SDD')
                                     nState['rid'] = true;
                                 this.setState(nState)
                             }}></FormInputSelect>

            {this.state.mod_pagamento == 'Addebbito diretto su CC SDD' && <React.Fragment>
                <FormInputText upperCase={true} label="Titolare del CC"
                               value={this.state.pagam_titolare}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({pagam_titolare: se.target.value})}/>

                <FormInputText upperCase={true} label="Cod. fiscale del titolare"
                               value={this.state.pagam_cod_fis_titolare}
                               direction={FormInputContainer.HORIZONTAL}
                               disabled={this.props.systemInserted}
                               onChange={se => this.setState({pagam_cod_fis_titolare: se.target.value})}/>

                <FormIbanInput upperCase={true}
                               value={this.state.pagam_iban}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({pagam_iban: se.target.value})}/>
            </React.Fragment>}

            <FormInputRadio label="RID" checked={this.state.rid}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    rid:
                                        this.state.rid ? false : true
                                })
                            }}/>

            <FormInputRadio label="Bolletta WEB" checked={this.state.web_payment}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    web_payment:
                                        this.state.web_payment ? false : true
                                })
                            }}/>

            <FormInputRadio label="Da tablet" checked={this.state.from_tablet}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    from_tablet:
                                      this.state.from_tablet ? false : true
                                })
                            }}/>


        </React.Fragment>;
    }
}

class ContractTypeCorporate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dat_stip_contratto: moment(),
            web_payment: false,
            rid: false,
            ...this.props.values,
        }
    }

    componentDidUpdate(prevPros, prevState) {
        if (JSON.stringify(this.props.values) != JSON.stringify(prevPros.values)) {
            console.log(`Settings ${JSON.stringify(this.props.values)}`);
            this.setState(this.props.values);
        }

        if (JSON.stringify(this.state) != JSON.stringify(prevState) && this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return <React.Fragment>

            <FormInputSelect values={{
                'Small': 'Small',
                'Medium': 'Medium',
                'Micro': 'Micro',
              }}
              label="Tipologia contatore"
              value={this.state.kwMeterType}
              direction={FormInputContainer.HORIZONTAL}
              onChange={se => {
                  this.setState({kwMeterType: se})
            }}/>

            <FormInputDate value={this.state.dat_stip_contratto}
                           label="Data Stipula Contratto" requiredField={true}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_stip_contratto: se})
                           }}/>

            <FormInputText upperCase={true} label="Cognome e nome ragione sociale"
                           value={this.state.rag_soc} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({rag_soc: se.target.value})}/>

            <FormInputText upperCase={true} label="Forma giuridica"
                           value={this.state.giur_form} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({giur_form: se.target.value})}/>

            <FormInputText upperCase={true} label="Regime tariffario di diritto"
                           value={this.state.regime_tar} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({regime_tar: se.target.value})}/>
            <div className="row">
                <div className="col-sm-7">
                    <FormInputText upperCase={true} label="Indirizzo / Sede legale"
                                   value={this.state.legal_address} disabled={this.props.systemInserted}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({legal_address: se.target.value})}/>
                </div>
                <div className="col-sm-5">

                    <FormInputText upperCase={true} label="Numero"
                                   value={this.state.legal_address_n} disabled={this.props.systemInserted}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({legal_address_n: se.target.value})}/>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-4">
                    <FormInputText upperCase={true} label="Citta" disabled={this.props.systemInserted}
                                   value={this.state.legal_address_city}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({legal_address_city: se.target.value})}/>
                </div>
                <div className="col-sm-4">
                    <FormInputText upperCase={true} label="Provincia " disabled={this.props.systemInserted}
                                   value={this.state.legal_address_pro}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({legal_address_pro: se.target.value})}/>

                </div>
                <div className="col-sm-4">
                    <FormInputText upperCase={true} label="CAP" disabled={this.props.systemInserted}
                                   value={this.state.legal_address_cap}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({legal_address_cap: se.target.value})}/>
                </div>
            </div>

            <FormInputText upperCase={true} label="P.IVA"
                           value={this.state.piva}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({piva: se.target.value})}/>

            <FormInputText upperCase={true} label="Codice fiscale"
                           value={this.state.cf}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({cf: se.target.value})}/>

            <FormInputText upperCase={true} label="Telefono"
                           value={this.state.tel} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({tel: se.target.value})}/>

            <FormInputText upperCase={true} label="Fax"
                           value={this.state.fax} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({fax: se.target.value})}/>

            <FormInputText upperCase={true} label="Cellulare"
                           value={this.state.cell} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({cell: se.target.value})}/>

            <FormInputText upperCase={true} label="Rappresentate legale"
                           value={this.state.rappr_legal_name} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({rappr_legal_name: se.target.value})}/>

            <FormInputText label="Email" disabled={this.props.systemInserted}
                           value={this.state.rappr_legal_email}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({rappr_legal_email: se.target.value})}/>

            <FormInputText label="Email PEC"
                           value={this.state.pec}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pec: se.target.value})}/>

            <h3>Referente o amministratore di condominio</h3>

            <FormInputText upperCase={true} label="Nome"
                           value={this.state.ref_name} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_name: se.target.value})}/>

            <FormInputText upperCase={true} label="Cognome"
                           value={this.state.ref_surname} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_surname: se.target.value})}/>

            <FormInputSelect values={{
                'Persona fisica': 'Persona giuridica',
                'Persona giuridica': 'Persona giuridica'
            }}
                             label="Forma giuridica"
                             value={this.state.ref_pif} disabled={this.props.systemInserted}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({ref_pif: se})
                             }}/>

            <FormInputText upperCase={true} label="CF / Partita IVA Referente /  Amministratore di Condominio"
                           value={this.state.ref_piva}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_piva: se.target.value})}/>

            <FormInputText upperCase={true} label="Telefono Referente /  Amministratore di Condominio"
                           value={this.state.ref_tel} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_tel: se.target.value})}/>

            <FormInputRadio label="Fatturazione diversa sede cliente" checked={this.state.differFatt}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    differFatt:
                                        this.state.differFatt ? false : true
                                })
                            }}/>

            {this.state.differFatt && <React.Fragment>

                <FormInputText upperCase={true} label="C/o" disabled={this.props.systemInserted}
                               value={this.state.differFatt_co}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({differFatt_co: se.target.value})}/>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="differFatt_"
                             disabled={this.props.systemInserted}
                />

                <FormInputText upperCase={true} label="Cig" disabled={this.props.systemInserted}
                               value={this.state.differFatt_cig}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({differFatt_cig: se.target.value})}/>

                <FormInputText upperCase={true} label="Cup" disabled={this.props.systemInserted}
                               value={this.state.differFatt_cup}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({differFatt_cup: se.target.value})}/>

            </React.Fragment>}

            <h3>Fornitura</h3>
            <FormInputSelect values={{
                'Gas naturale': 'Gas naturale',
                'Energia elettrica': 'Energia elettrica'
            }}
                             label="Tipo fornitura"
                             value={this.state.type}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({type: se})
                             }}></FormInputSelect>

            <FormInputRadio label="Provenienza mercato libero" checked={this.state.freeMarket}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    freeMarket:
                                        this.state.freeMarket ? false : true
                                })
                            }}/>

            {this.state.type == 'Gas naturale' && <React.Fragment>

                <FormInputText upperCase={true} label="M3"
                               value={this.state.m3}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({m3: se.target.value})}/>

                <h3>Sito gas naturale</h3>

                <FormInputText upperCase={true} label="Attuale soc. di vendita"
                               value={this.state.gas_soc_ve}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({gas_soc_ve: se.target.value})}/>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="gas_"
                             disabled={this.props.systemInserted}
                />


                <FormInputText upperCase={true} label="Contatore / Matricola:"
                               value={this.state.gas_matricola}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({gas_matricola: se.target.value})}/>


                <FormInputText upperCase={true} label="Accessibile"
                               value={this.state.gas_access}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({gas_access: se.target.value})}/>

                <FormInputText upperCase={true} label="Codice Pdr"
                               value={this.state.gas_pdr}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({gas_pdr: se.target.value})}/>

            </React.Fragment>}

            {this.state.type == 'Energia elettrica' && <React.Fragment>


                <h3>Sito Energia elettrica</h3>

                <FormInputText upperCase={true} label="Attuale soc. di vendita"
                               value={this.state.ele_soc_vec}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({ele_soc_vec: se.target.value})}/>

                <FormInputText upperCase={true} label="Livello tensione"
                               value={this.state.ele_lvl_tension}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({ele_lvl_tension: se.target.value})}/>

                <h3>Indirizzo del punto di prelievo</h3>
                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="ele_"
                             disabled={this.props.systemInserted}
                />

                <FormInputText upperCase={true} label="Consumo annuo TOT presunto in kWh"
                               value={this.state.ele_kwh_annuo}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({ele_kwh_annuo: se.target.value})}/>


                <FormInputText upperCase={true} label="Potenza (kW)"
                               value={this.state.ele_kw}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({ele_kw: se.target.value})}/>

            </React.Fragment>}

            <h3>Dati pagamento</h3>

            <FormInputText upperCase={true} label="Intestatario del CC"
                           value={this.state.pagam_titolare} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pagam_titolare: se.target.value})}/>

            <FormInputText upperCase={true} label="Cod. fiscale intestario"
                           value={this.state.pagam_cod_fis_titolare} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pagam_cod_fis_titolare: se.target.value})}/>

            <FormInputText upperCase={true} label="CC Rappr. Legale"
                           value={this.state.pagam_iban_cc} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pagam_iban_cc: se.target.value})}/>

            <FormIbanInput upperCase={true} label="IBAN"
                           value={this.state.pagam_iban}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pagam_iban: se.target.value})}/>

            <FormInputRadio label="RID" checked={this.state.rid}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    rid:
                                        this.state.rid ? false : true
                                })
                            }}/>

            <FormInputRadio label="Bolletta WEB" checked={this.state.web_payment}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    web_payment:
                                        this.state.web_payment ? false : true
                                })
                            }}/>

            <FormInputRadio label="Da tablet" checked={this.state.from_tablet}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    from_tablet:
                                      this.state.from_tablet ? false : true
                                })
                            }}/>

        </React.Fragment>;
    }
}

class ContractTypeBusiness extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ref_doc_released_date: moment(),
            dat_inizio_valid: moment(),
            dat_fine_valid: moment(),
            dat_stip_contratto: moment(),
            web_payment: false,
            rid: false,
            ...this.props.values,
        };
    }

    componentDidUpdate(prevPros, prevState) {
        if (JSON.stringify(this.props.values) != JSON.stringify(prevPros.values)) {
            console.log(`Settings ${JSON.stringify(this.props.values)}`);
            this.setState(this.props.values);
        }

        if (JSON.stringify(this.state) != JSON.stringify(prevState) && this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        return <React.Fragment>

            <FormInputDate value={this.state.dat_stip_contratto}
                           label="Data Stipula Contratto" requiredField={true}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_stip_contratto: se})
                           }}/>

            <FormInputText upperCase={true} label="Cognome e nome / ragione sociale"
                           value={this.state.rag_soc} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({rag_soc: se.target.value})}/>

            <FormInputText upperCase={true} label="Forma giuridica"
                           value={this.state.giur_form}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({giur_form: se.target.value})}/>


            <FormInputText upperCase={true} label="P.IVA"
                           value={this.state.piva} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({piva: se.target.value})}/>

            <FormInputText upperCase={true} label="Codice fiscale azienda"
                           value={this.state.ci_azi} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ci_azi: se.target.value})}/>


            <FormInputText upperCase={true} label="Telefono"
                           value={this.state.tel} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({tel: se.target.value})}/>

            <FormInputText upperCase={true} label="Cellulare"
                           value={this.state.cell} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({cell: se.target.value})}/>


            <FormInputText label="Email"
                           value={this.state.email} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({email: se.target.value})}/>

            <FormInputText label="Email PEC"
                           value={this.state.pec}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({pec: se.target.value})}/>


            <h3>Referente o amministratore di condominio</h3>

            <FormInputText upperCase={true} label="Nome"
                           value={this.state.ref_name} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_name: se.target.value})}/>

            <FormInputText upperCase={true} label="Cognome"
                           value={this.state.ref_surname} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_surname: se.target.value})}/>


            <FormInputText upperCase={true} label="Codice fiscale"
                           value={this.state.ref_piva} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_piva: se.target.value})}/>


            <FormInputText upperCase={true} label="Telefono"
                           value={this.state.tel} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_tel: se.target.value})}/>


            <DocumentoIdentitaSelect value={this.state.ref_doc_identita}
                                     disabled={this.props.systemInserted}
                                     onChange={se => this.setState({ref_doc_identita: se})}/>

            <FormInputText upperCase={true} label="Numero documento"
                           value={this.state.ref_doc_number} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_doc_number: se.target.value})}/>

            <FormInputText upperCase={true} label="Rilasciato da"
                           value={this.state.ref_doc_released_by} disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({ref_doc_released_by: se.target.value})}/>

            <FormInputDate value={this.state.ref_doc_released_date}
                           label="Data rilascio" disabled={this.props.systemInserted}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({ref_doc_released_date: se})
                           }}/>

            <h3>Indirizzo sede legale</h3>

            <AddressData onChange={state => {
                this.setState(state)
            }}
                         values={this.state} prefix="legalAddress_"
                         disabled={this.props.systemInserted}
            />


            <FormInputRadio label="Sede cliente diversa" checked={this.state.differSite}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    differSite:
                                        this.state.differSite ? false : true
                                })
                            }}/>

            {this.state.differSite && <React.Fragment>

                <FormInputText upperCase={true} label="C/o"
                               value={this.state.differSite_co} disabled={this.props.systemInserted}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({differSite_co: se.target.value})}/>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             disabled={this.props.systemInserted}
                             values={this.state} prefix="differSite_"
                />

            </React.Fragment>}

            <FormDashline/>

            <FormInputText upperCase={true} label="Codice ufficio"
                           value={this.state.office_cod}
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => this.setState({office_cod: se.target.value})}/>

            <FormInputDate value={this.state.dat_inizio_valid}
                           label="Data inizio validità"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_inizio_valid: se})
                           }}/>

            <FormInputDate value={this.state.dat_fine_valid}
                           label="Data fine validità"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => {
                               this.setState({dat_fine_valid: se})
                           }}/>

            <h3>Dati fornitura</h3>

            <FormInputSelect values={{
                'Gas naturale': 'Gas naturale',
                'Energia elettrica': 'Energia elettrica'
            }}
                             label="Tipo fornitura"
                             value={this.state.type}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 this.setState({type: se})
                             }}></FormInputSelect>


            <FormInputRadio label="Provenienza mercato libero" checked={this.state.freeMarket}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    freeMarket:
                                        this.state.freeMarket ? false : true
                                })
                            }}/>

            {this.state.type == 'Energia elettrica' && <React.Fragment>

                <FormInputRadio label="Uso non professionale" checked={this.state.non_prof_use}
                                direction={FormInputContainer.HORIZONTAL}
                                onClick={se => {
                                    this.setState({
                                        non_prof_use:
                                            this.state.non_prof_use ? false : true
                                    })
                                }}/>


                <FormInputText upperCase={true} label="Attuale società vendita"
                               value={this.state.cur_soc_ven}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({cur_soc_ven: se.target.value})}/>

                <FormInputText upperCase={true} label="Potenza kW"
                               value={this.state.kw}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({kw: se.target.value})}/>

                <FormInputText upperCase={true} label="Livello tensione W"
                               value={this.state.lvl_ten_w}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({lvl_ten_w: se.target.value})}/>

                <h3>Indirizzo fornitura</h3>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="eleAddress_"
                />

                <h3>Indirizzo di fatturazione</h3>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="eleAddress01_"
                             disabled={this.props.systemInserted}
                />

            </React.Fragment>}


            {this.state.type == 'Gas naturale' && <React.Fragment>


                <FormInputRadio label="Pressione in bassa pressione alle condizioni di esercizio della rete di distribuzione. Consegna al contatore"
                                checked={this.state.low_press}
                                direction={FormInputContainer.HORIZONTAL}
                                onClick={se => {
                                    this.setState({
                                        low_press:
                                            this.state.low_press ? false : true
                                    })
                                }}/>


                <FormInputRadio label="Uso non professionale"
                                checked={this.state.gas_non_prof}
                                direction={FormInputContainer.HORIZONTAL}
                                onClick={se => {
                                    this.setState({
                                        gas_non_prof:
                                            this.state.gas_non_prof ? false : true
                                    })
                                }}/>


                <FormInputSelect values={{
                    'Riscaldamento': 'Riscaldamento',
                    'Produzione': 'Produzione',
                    'Produzione e riscaldamento': 'Produzione e riscaldamento',
                    'Cottura e/o acqua calda': 'Cottura e/o acqua calda'
                }}
                                 label="Profili di consumo"
                                 value={this.state.prof_consi}
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => {
                                     this.setState({prof_consi: se})
                                 }}></FormInputSelect>


                <FormInputSelect values={{
                    'Accessibile': 'Accessibile',
                    'Non accessibile': 'Non accessibile',
                }}
                                 label="Posizione contatore"
                                 value={this.state.pos_conta}
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => {
                                     this.setState({pos_conta: se})
                                 }}></FormInputSelect>


                <FormInputText upperCase={true} label="Matricola contatore"
                               value={this.state.mat_cont}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({mat_cont: se.target.value})}/>

                <h3>Indirizzo fornitura</h3>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="gasAddress00_"
                />

                <h3>Indirizzo di fatturazione</h3>

                <AddressData onChange={state => {
                    this.setState(state)
                }}
                             values={this.state} prefix="gasAddress01_"
                             disabled={this.props.systemInserted}
                />

            </React.Fragment>}

            <FormInputSelect values={{
                'Bollettino postale': 'Bollettino postale',
                'Bonifico': 'Bonifico',
                'Addebbito diretto su CC SDD': 'Addebbito diretto su CC SDD'
            }}
                             label="Modalità di pagamento"
                             value={this.state.mod_pagamento}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => {
                                 let nState = {mod_pagamento: se};
                                 if (se === 'Addebbito diretto su CC SDD')
                                     nState['rid'] = true;
                                 this.setState(nState)
                             }}></FormInputSelect>

            {this.state.mod_pagamento == 'Addebbito diretto su CC SDD' && <React.Fragment>
                <FormInputText upperCase={true} label="Intestatario del CC"
                               value={this.state.pagam_titolare}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({pagam_titolare: se.target.value})}/>

                <FormInputText upperCase={true} label="Cod. fiscale del titolare e delegato del C/C"
                               value={this.state.pagam_cod_fis_titolare}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({pagam_cod_fis_titolare: se.target.value})}/>

                <FormInputText upperCase={true} label="Cognome e nome del sottoscritto del modulo (titolare o delegato ad operare sul CC indicato)"
                               value={this.state.name_sur_titoasd23}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({name_sur_titoasd23: se.target.value})}/>

                <FormIbanInput upperCase={true} label="IBAN"
                               value={this.state.pagam_iban}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({pagam_iban: se.target.value})}/>

            </React.Fragment>}

            <FormInputRadio label="RID" checked={this.state.rid}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    rid:
                                        this.state.rid ? false : true
                                })
                            }}/>

            <FormInputRadio label="Bolletta WEB" checked={this.state.web_payment}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    web_payment:
                                        this.state.web_payment ? false : true
                                })
                            }}/>

            <FormInputRadio label="Da tablet" checked={this.state.from_tablet}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                                this.setState({
                                    from_tablet:
                                      this.state.from_tablet ? false : true
                                })
                            }}/>

        </React.Fragment>;
    }
}

class ContractsHistoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ContractsHistories: []
        };

    }

    componentDidMount() {
        fetch('api', 'Contracts', 'get', this.props.id).then(msg => {
            this.setState({...msg.body, loading: false});
        });
    }

    render() {
        return <Modal onClose={this.props.onDone}>
            {this.state.loading && <Loader/>}

            {!this.state.loading && <h2>Storico contratto {this.state.uniqueUuid}</h2>}

            {this.state.ContractsHistories.length < 1 && !this.state.loading &&
            <p className="text-info">Nessun cambiamento di stato trovato</p>}

            <ul className="list-group clear-list m-t">
                {this.state.ContractsHistories.map(entry => {
                    return <li className="list-group-item" style={{width: '100%'}}>
              <span className="float-right">
                  {moment(entry.createdAt).format('DD/MM/YY HH:mm')}
              </span>
                        {entry.message}
                    </li>
                })}
            </ul>

            <button className="btn btn-primary" onClick={this.props.onDone}>Chiudi</button>
        </Modal>;
    }
}


class ImportConfigurationSelect extends React.Component {
    render() {
        return <React.Fragment>
            <ApiMultiSelect
                model="ImportConfigurations"
                formatRow={row => {
                    return `${row.name}`;
                }}
                label="Tipo contratto"
                direction={FormInputContainer.HORIZONTAL}
                {...this.props}
            />
        </React.Fragment>;
    }
}

function ForceStateModal( props ){

    const [ phase, setPhase ] = React.useState( 'form' );
    const [ filesId, setFilesId ] = React.useState( false );
    const [ contractState, setContractState ] = React.useState( false );
    const [ result, setResult ] = React.useState( false );

    const onSubmit = se => {
        se.preventDefault();

        if( !contractState || !contractState.id )
            return;

        setPhase( 'loading' );

        fetch('api', 'Contracts', 'forceState', { filesId , ContractStateId: contractState.id } ).then( data => {
            window.easylifeBgTasksUpdate();
            props.onDone();
        });
    };

    const onDone = se => {
        if( se && se.preventDefault )
            se.preventDefault();
        if (props.onDone) props.onDone();
    }

    const contractStateFilter = {};

    return <Modal onClose={props.onDone}>
        {phase == 'form' && <form onSubmit={onSubmit}>

            <label>File con codici contratto</label>

            <FileUploadInput onChange={setFilesId} filesId={filesId}/>

            <ContractStateSelect value={contractState}
                                 requiredField={true}
                                 direction={FormInputContainer.HORIZONTAL}
                                 {...contractStateFilter}
                                 onChange={se => {
                                     setContractState(se )
                                 }}/>

            <FormButtons saveLabel="Carica file" direction={FormInputContainer.HORIZONTAL}
                         onSave={onSubmit}
                         onCancel={onDone}/>
        </form>}

        {phase == 'loading' && <Loader/>}

        {phase == 'complete' && <React.Fragment>
            <h3>Importazione completata</h3>

            <p>Contratti aggiornati: {result.contractSaved}</p>
            <p>Contratti non trovati: {result.contractSkipped_notFound}</p>
            <p>Contratti saltati per canale non corrispondente: {result.contractSkipped_invalidChannel}</p>
            <p>Vendite efficenza aggiornate: {result.enelxSellsUpdated}</p>

            <button className="btn btn-primary" onClick={onDone}>
                Chiudi
            </button>


        </React.Fragment>}
    </Modal>;
}

class ImportContractsModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            configId: false,
            importDate: moment(),
            phase: 'form' // form | loading | results
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(se) {
        se.preventDefault();

        this.setState({phase: 'loading'});

        let timeout = setTimeout(() => {
            this.setState({phase: 'results', results: false});
        }, 5000);

        fetch('api', 'Contracts', 'import', this.state).then(data => {
            clearTimeout(timeout);
            if (data.body)
                this.setState({phase: 'results', results: data.body});
        });
    }

    render() {
        return <Modal onClose={this.props.onDone}>
            {this.state.phase == 'form' && <form onSubmit={this.onSubmit}>

                <ChannelSelect
                    direction={FormInputContainer.HORIZONTAL}
                    value={this.state.Channel}
                    onChange={v => {
                        this.setState({Channel: v})
                    }}/>

                <label>File da importare</label>

                <FileUploadInput onChange={filesId => {
                    this.setState({filesId: filesId})
                }} filesId={this.state.filesId}/>

                <FormInputSelect
                  direction={FormInputContainer.HORIZONTAL}
                  label="Versione"
                  values={{'2': 'Nuova'}}
                  value={this.state.version}
                  onChange={v => {
                      this.setState({version: v})
                  }}
                />

                <FormInputDate value={this.state.importDate}
                               label="Data importazione"
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({importDate: se})}/>


                <FormButtons saveLabel="Carica file" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>
            </form>}

            {this.state.phase == 'loading' && <Loader/>}

            {this.state.phase == 'results' && <React.Fragment>
                <h3>Importazione avviata</h3>
                {!this.state.results && <h4>Puoi verificarne lo stato da Contratti / Stato importazioni</h4>}

                {this.state.results && <React.Fragment>
                    <p>Contratti aggiornati: {this.state.results.contractsChanged}</p>
                    <p>Errori: {this.state.results.errors}</p>

                    {this.state.results.infos.map(info => {
                        return <p>{info}</p>;
                    })}
                </React.Fragment>}

                <button className="btn btn-primary" onClick={se => {
                    this.props.onDone()
                }}>
                    Chiudi
                </button>


            </React.Fragment>}
        </Modal>;
    }
}

class ContractsImports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reportModal: false
        };

    }

    reloadData() {
        fetch('api', 'ContractsImports', 'select').then(msg => {
            this.setState({list: msg.body});
            this.installOnSaveHook();
        });
    }

    componentDidMount() {
        this.reloadData();
    }

    componentWillUnmount() {
        if (this.onSaveHook)
            this.onSaveHook.abort();

        this.onSaveHook = false;
    }

    installOnSaveHook() {

        if (this.onSaveHook)
            this.onSaveHook.abort();

        this.onSaveHook = fetchHook('ContractsImports', 'onSave');

        this.onSaveHook.then(data => {
            this.reloadData(true);
        }).catch(() => {
        });
    }

    render() {

        if (!this.state.list)
            return <Loader/>;

        return <React.Fragment>
            <Breadcumbs title="Contratti" items={{'Home': '/'}}/>

            {this.state.reportModal && <Modal onClose={se => {
                this.setState({reportModal: false})
            }}>
                <React.Fragment>
                    <h4>Importazione completata</h4>

                    <p>Contratti aggiornati: {this.state.reportModal.contractsChanged}</p>
                    <p>Di cui residenziali: {this.state.reportModal.residential}</p>
                    <p>... e business: {this.state.reportModal.business}</p>
                    <p>Errori: {this.state.reportModal.errors}</p>

                    {this.state.reportModal.infos.map(info => {
                        return <p>{info}</p>;
                    })}

                    <button className="btn btn-primary" onClick={se => {
                        this.setState({reportModal: false})
                    }}>
                        Chiudi
                    </button>


                </React.Fragment>
            </Modal>}

            <div className="wrapper wrapper-content  animated fadeInRight">
                <BoxContainer title="Importazione contratti">
                    <Row>
                        <div className="col-md-12">
                            <div className="project-list">

                                <table className="table table-hover">
                                    <tbody>
                                    {this.state.list.map((cImport,i) => {

                                        let perc = 0;

                                        try {
                                            perc = cImport.itemDone / cImport.itemCount;
                                            perc = Math.ceil(perc * 100);
                                        } catch (e) {
                                        }

                                        const author = cImport.Author ? `${cImport.Author.name} ${cImport.Author.surname}` : '-';

                                        return <tr>
                                            <td className="project-status">
                                                {perc == 100 ?
                                                    <span className="label label-primary">Completata</span>
                                                    : <span className="label label-warning">In Elaborazione</span>}
                                            </td>
                                            <td className="project-title">
                                                <a href="project_detail.html">{cImport.filename}</a>
                                                <br/>
                                                <small>Creato il {moment(cImport.createdAt).format('DD MMM YYYY , HH:mm')}</small>
                                            </td>
                                            <td className="project-completion">
                                                <small>Contratti elaborati: {cImport.itemDone} / {cImport.itemCount}</small>
                                                <div className="progress progress-mini">
                                                    <div style={{width: perc + "%"}} className="progress-bar"></div>
                                                </div>
                                            </td>
                                            <td className="project-actions">
                                                <a href="#" className="btn btn-white btn-sm"
                                                   onClick={se => {
                                                       se.preventDefault();
                                                       this.setState({reportModal: JSON.parse(cImport.lastStatusMessage)})
                                                   }}>
                                                    <i className="fa fa-folder"></i> Report </a>
                                                { i == 0 && <ConfirmableButton href="#" className="btn btn-danger btn-sm"
                                                 onClick={se=>{
                                                     se.preventDefault();
                                                     fetch('api', 'ContractsImports', 'revert').then(msg => {
                                                         window.location.reload();
                                                     });
                                                 }}>
                                                    <i className="fa fa-undo"></i> Annulla
                                                </ConfirmableButton> }
                                            </td>
                                        </tr>;
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </Row>
                </BoxContainer>
            </div>
        </React.Fragment>;
    }
}


class ChannelVerificationImports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reportModal: false,
            pagination: {
                limit: 20,
                page: 1
            }
        };

    }

    async reloadData(silent = false, autoReload = false) {
        return new Promise(success => {
            if (!silent)
                this.setState({list: false});

            fetch('api', 'ChannelVerificationImports', 'select', this.state).then(msg => {
                this.setState({list: msg.body});
                success(msg.body);

                if (autoReload) {
                    setTimeout(() => {
                        this.reloadData(true, true);
                    }, 3000);
                }

            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevState.pagination) != JSON.stringify(this.state.pagination)) {
            this.reloadData(false);
        }
    }

    voidImport(importId) {
        fetch('api', 'ChannelVerificationImports', 'void', importId).then(msg => {
            this.reloadData();
        });
    }

    componentDidMount() {
        this.reloadData(false, true);

    }

    componentWillUnmount() {
    }

    render() {

        if (!this.state.list)
            return <Loader/>;

        return <React.Fragment>
            <Breadcumbs title="Importazioni verifica canali" items={{'Home': '/'}}/>

            {this.state.reportModal &&
            <ChannelVerificationImportResultsModal
                onDone={se => {
                    this.setState({reportModal: false})
                }}
                id={this.state.reportModal.id}/>}

            <div className="wrapper wrapper-content  animated fadeInRight">
                <BoxContainer title="Importazione contratti">
                    <Row>
                        <div className={"col-12"}>
                            <Pagination onChange={p => {
                                this.setState({pagination: p})
                            }}
                                        hideTotals={false}
                                        filters={this.props.filters}
                                        limit={this.state.pagination.limit} page={this.state.pagination.page}
                                        table={"ChannelVerificationImports"}/>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-12">
                            <div className="project-list">

                                <table className="table table-hover">
                                    <tbody>
                                    {this.state.list.map(cImport => {

                                        let perc = 0;

                                        try {
                                            perc = cImport.itemDone / cImport.itemCount;
                                            perc = Math.ceil(perc * 100);
                                        } catch (e) {
                                        }

                                        const author = cImport.Author ? `${cImport.Author.name} ${cImport.Author.surname}` : '-';

                                        return <tr>
                                            <td className="project-status">
                                                {cImport.complete == 1 ? <span className="label label-primary">Completata</span>
                                                    : <span className="label label-warning">In Elaborazione</span>}
                                            </td>
                                            <td className="project-title">
                                                <a href="project_detail.html">{cImport.filename}</a>
                                                <br/>
                                                <small>Creato il {moment(cImport.createdAt).format('DD MMM YYYY , HH:mm')}</small>
                                            </td>
                                            <td className="project-completion">
                                                <small>Importazioni elaborate: {cImport.itemDone} / {cImport.itemCount}</small>
                                                <div className="progress progress-mini">
                                                    <div style={{width: perc + "%"}} className="progress-bar"></div>
                                                </div>
                                            </td>
                                            <td className="project-actions">
                                                <a href="#" className="btn btn-white btn-xs"
                                                   onClick={se => {
                                                       se.preventDefault();
                                                       this.setState({reportModal: cImport})
                                                   }}>
                                                    <i className="fa fa-folder"></i> Report </a>
                                                <a href="#" className="btn btn-white btn-xs"
                                                   onClick={se => {
                                                       se.preventDefault();
                                                       this.voidImport(cImport.id)
                                                   }}>
                                                    <i className="fa fa-times"></i>Annulla</a>
                                            </td>
                                        </tr>;
                                    })}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </Row>
                </BoxContainer>
            </div>
        </React.Fragment>;
    }
}

class NewContractModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            LeadId: this.props.leadId,
            /*state: 'Cartaceo da caricare',*/
            name: '',
            extraJsonData: '{}',
            loadingLeadData: false,
            Products: [],
            Notes: [],
            inputChangeInterval: 0,
            telephoneWarningTp2: false,
            telephoneExpiredTp2: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleTelephoneChange = this.handleTelephoneChange.bind(this);
        this.checkWhitelistNumber = this.checkWhitelistNumber.bind(this);

        this.loadLead();
    }

    componentDidMount() {
        apiFormInit('Contracts', this);

        fetch('api', 'ContractStates', 'select').then(data => {
            this.setState({
                systemInsertedStates: data.body.filter(state => {
                    return state.canBeInitialState && state.name.match(/Inserito a sistema/i);
                })
            });
        });

        fetch('api', 'ContractStates', 'select').then(data => {
            this.setState({
                systemInsertedStatesValidation: data.body.filter(state => {
                    return state.canBeInitialState && state.name.match(/da validare/i);
                })
            });
        });
    }

    loadLead() {

        if (!this.state.LeadId)
            return;

        fetch('api', 'Leads', 'fastGet', this.state.LeadId).then(msg => {
            let o = {};

            ['businessName', 'name', 'surname', 'email', 'address', 'city', 'province',
                'cap', 'civicNumber', 'fiscalCode', 'telephone', 'telephoneSec'].map(key => {
                o[key] = msg.body[key];
            });

            this.setState(o,()=>{
                this.checkWhitelistNumber(this.state.telephone);
            });
        });
    }

    componentDidUpdate(prevPros, prevState) {

        if (this.state.LeadId != prevState.LeadId) {
            this.setState({loadingLeadData: true});
            this.loadLead();
        }

        if( this.state.email ){
            try {
                let extraJsonData = JSON.parse( this.state.extraJsonData );

                if( typeof( extraJsonData.web_payment ) != 'undefined' ){
                    if( !extraJsonData.web_payment ){
                        extraJsonData.web_payment = true;
                        this.setState({ extraJsonData: JSON.stringify( extraJsonData ) });
                    }
                }
            } catch (e) { }

        }

        try {
            let extraJsonData = JSON.parse( this.state.extraJsonData );

            if( extraJsonData.pagam_iban ){
                if( !extraJsonData.rid ){
                    extraJsonData.rid = true;
                    this.setState({ extraJsonData: JSON.stringify( extraJsonData ) });
                }
            }
        } catch (e) { }

    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('Contracts', this);
    }


    checkWhitelistNumber(number){

        fetch('api', 'TelephonesWhitelist', 'check', {number: number}).then(data => {
            this.setState({telephone: number, telephoneWarningTp2: !data.body.status || data.body.expired, telephoneExpiredTp2: data.body.expired});
        });

    }

    handleTelephoneChange(se) {

        clearTimeout(this.state.inputChangeInterval);

        let number = se.target.value;

        if(!this.state.isTp2 || (this.state.telephone && this.state.telephone.length===0))
            return this.setState({telephone: number, telephoneWarningTp2: false, telephoneExpiredTp2: false});

       // let prevNumber = this.state.telephone ? this.state.telephone : null;
        let _this = this;

        if (!number) return  _this.setState({telephone: null, telephoneWarningTp2: false, telephoneExpiredTp2: false});

        let t = setTimeout(function () {

            //if (!prevNumber) return  _this.setState({telephone: number, telephoneWarningTp2: false});
            _this.checkWhitelistNumber(number);


        }, 200);

        this.setState({inputChangeInterval: t});


    }

    render() {

        if (!this.state.systemInsertedStates || !this.state.systemInsertedStatesValidation)
            return <Modal>
                <Loader/>
            </Modal>;

        let contractStateFilter =
            this.state.id >= 1 ?
                {canBeSelected: true}
                : {canBeInitialState: true};

        if (can('Contracts.canSelectAnyState'))
            contractStateFilter = {};

        let jsonData = {};
        try {
            jsonData = JSON.parse( this.state.extraJsonData );
        } catch (e) {

        }

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <div className="row">
                    <div className="col-sm-6">
                        <FormInputRadio label="Inserito a sistema" checked={this.state.systemInsertedA}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {

                                            let nState = {};

                                            if (this.state.systemInsertedA) {
                                                nState = {systemInserted: false, systemInsertedA: false, systemInsertedV: false};
                                            } else {
                                                nState = {systemInserted: true, systemInsertedA: true, systemInsertedV: false};
                                            }

                                            if (!this.state.systemInserted && this.state.systemInsertedStates.length > 0) {
                                                nState.ContractState = this.state.systemInsertedStates[0];
                                                nState.ContractStateId = this.state.systemInsertedStates[0].id;
                                                nState.state = this.state.systemInsertedStates[0].name;
                                            }
                                            this.setState(nState)
                                        }}/>
                    </div>
                    <div className="col-sm-6">
                        <FormInputRadio label="Riduci campi" checked={this.state.systemInserted}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {
                                            this.setState({
                                                systemInserted: !this.state.systemInserted,
                                                systemInsertedA: false,
                                                systemInsertedV: false
                                            })
                                        }}
                        />
                    </div>

                    <div className="col-sm-6">
                        <FormInputRadio label="Da validare" checked={this.state.systemInsertedV}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {

                                            let nState = {};

                                            if (this.state.systemInsertedV) {
                                                nState = {systemInserted: false, systemInsertedV: false, systemInsertedA: false};
                                            } else {
                                                nState = {systemInserted: true, systemInsertedV: true, systemInsertedA: false};
                                            }

                                            if (!this.state.systemInserted && this.state.systemInsertedStatesValidation.length > 0) {
                                                nState.ContractState = this.state.systemInsertedStatesValidation[0];
                                                nState.ContractStateId = this.state.systemInsertedStatesValidation[0].id;
                                                nState.state = this.state.systemInsertedStatesValidation[0].name;
                                            }

                                            this.setState(nState)
                                        }}/>
                    </div>

                    {(!this.state.isTp2 || can('Contracts.canEditTp2')) && <div className={'col-sm-6'}>
                        <FormInputRadio label="TP2" checked={this.state.isTp2}
                                        onClick={se => {
                                                this.setState({isTp2: !this.state.isTp2}, () => {
                                                    if (this.state.isTp2 && this.state.telephone && this.state.telephone.length > 0) this.checkWhitelistNumber(this.state.telephone);
                                                });
                                        }}
                                        direction={FormInputContainer.HORIZONTAL}/>
                    </div>}

                    <div className={'col-sm-6'}>
                        <FormInputRadio label="Chiamata consensi" checked={this.state.isChiamataConsensi}
                                        onClick={se => {
                                            this.setState({isChiamataConsensi: !this.state.isChiamataConsensi} );
                                        }}
                                        direction={FormInputContainer.HORIZONTAL}/>
                    </div>

                </div>


                <ContractStateSelect value={this.state.ContractState}
                                     requiredField={true}
                                     direction={FormInputContainer.HORIZONTAL}
                                     {...contractStateFilter}
                                     onChange={se => {
                                         this.setState({
                                             ContractState: se,
                                             ContractStateId: se ? se.id : null,
                                             state: se ? se.name : null
                                         })
                                     }}/>


                { /*!this.state.LeadId &&*/ <React.Fragment>
                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Nome" value={this.state.name}
                                           requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="name"
                                           onChange={se => this.setState({name: se.target.value})}/>
                        </div>
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Cognome" value={this.state.surname}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="surname"
                                           onChange={se => this.setState({surname: se.target.value})}/>
                        </div>
                        <div className="col-sm-12">
                            <FormInputText upperCase={true} label="Ragione sociale" value={this.state.businessName}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="businessName"
                                           onChange={se => this.setState({businessName: se.target.value})}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Indirizzo" value={this.state.address}
                                           validation={this.state.validation} name="address"
                                           requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({address: se.target.value})}/>
                        </div>
                        <div className="col-sm-3">
                            <FormInputText upperCase={true} label="Civico" value={this.state.civicNumber}
                                           validation={this.state.validation} name="civicNumber"
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({civicNumber: se.target.value})}/>
                        </div>
                        <div className="col-sm-3">
                            <FormInputText upperCase={true} label="CAP" value={this.state.cap}
                                           validation={this.state.validation} name="cap" requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({cap: se.target.value})}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <FormInputText upperCase={true} label="Codice Fiscale / P.IVA" value={this.state.fiscalCode}
                                           validation={this.state.validation} name="fiscalCode" requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({fiscalCode: se.target.value})}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Provincia" value={this.state.province}
                                           validation={this.state.validation} name="province"
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({province: se.target.value})}/>
                        </div>
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Città" value={this.state.city}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="city"
                                           onChange={se => this.setState({city: se.target.value})}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Telefono" value={this.state.telephone}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="telephone"
                                           requiredField={true}
                                           enable={!(this.state.isTp2 && !can('Contracts.canEditTp2'))}
                                           onChange={this.handleTelephoneChange}/>
                        </div>
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Telefono Secondario" value={this.state.telephoneSec}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="telephoneSec"
                                           onChange={se => this.setState({telephoneSec: se.target.value})}/>
                        </div>
                        {this.state.isTp2 && this.state.telephoneWarningTp2 && <div className="col-12">
                            <div className={'alert alert-danger'}>
                                {this.state.telephoneExpiredTp2 ? <b>Numero di telefono presente in lista TP2 ma scaduto</b> : <b>Numero di telefono non presente in lista TP2</b>}
                            </div>
                        </div>}
                    </div>
                    <div className="row">
                        <div className="col-12">
                    <FormInputText label="Email (obbligatorio)" value={this.state.email}
                                   direction={FormInputContainer.HORIZONTAL}
                                   validation={this.state.validation} name="email"
                                   onChange={se => this.setState({email: se.target.value})}/>
                        </div>
                    </div>
                    {this.state.isTp2 && <>
                    <div className="row">
                        <div className="col-sm-6">
                           <FormInputText upperCase={true} label="Riferimento Contatto" value={this.state.refContact}
                                           validation={this.state.validation} name="refContact"
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           onChange={se => this.setState({refContact: se.target.value})}/>
                        </div>
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Riferimento Operatore" value={this.state.refOperator}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="refOperator"
                                           onChange={se => this.setState({refOperator: se.target.value})}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputSelect value={this.state.callerNumber} direction={FormInputContainer.HORIZONTAL}
                                             label="Numero Chiamante"
                                             values={{
                                                 '0': '0000',
                                             }}
                                             requiredField={true}
                                             onChange={se => this.setState({callerNumber: se})}/>
                        </div>

                    </div>
                    </>}

                    <FormDashline/>

                </React.Fragment>}

                <FormInputText upperCase={true} label="Codice Univoco" value={this.state.uniqueUuid}
                               direction={FormInputContainer.HORIZONTAL}
                               requiredField={true}
                               validation={this.state.validation} name="uniqueUuid"
                               onChange={se => this.setState({uniqueUuid: se.target.value})}/>

                {can('Contracts.canSelectChannelDuringSave') && <ChannelSelect value={this.state.Channel}
                                                                               validation={this.state.validation} name="ChannelId"
                                                                               direction={FormInputContainer.HORIZONTAL}
                                                                               onChange={se => this.setState({Channel: se, ChannelId: se ? se.id : null})}/>}

                {can('Contracts.canSelectChannelDuringSave') && <ChannelCodeSelect value={this.state.ChannelCode}
                                                                                   validation={this.state.validation} name="ChannelCodeId"
                                                                                   direction={FormInputContainer.HORIZONTAL}
                                                                                   onChange={se => this.setState({ChannelCode: se, ChannelCodeId: se ? se.id : null})}/>}

                {can('Contracts.canSelectSellingPointDuringSave') && <SellingPointSelect value={this.state.SellingPoint}
                                                                                         validation={this.state.validation} name="SellingPointId"
                                                                                         direction={FormInputContainer.HORIZONTAL}
                                                                                         onChange={se => this.setState({SellingPoint: se, SellingPointId: se ? se.id : null})}/>}

                <AdvancedProductSelect onChange={Products => {
                    this.setState({Products: Products})
                }}
                                       values={this.state.Products}/>

                {this.state.Products.length > 0 && <ContractTypesSelect label="Modulistica contratto abilitato" value={this.state.ContractType}
                                                                        validation={this.state.validation} name="ContractType"
                                                                        filters={{productId: this.state.Products[0].id}}
                                                                        requiredField={true}
                                                                        direction={FormInputContainer.HORIZONTAL}
                                                                        onChange={se => this.setState({ContractType: se, ContractTypeId: se ? se.id : null})}/>}

                <FormDashline/>

                <ContractServiceType value={this.state.serviceType}
                                     onChange={se => {
                                         this.setState({serviceType: se})
                                     }}/>

                <ContractTypeFactory
                    {...this.state}
                    type={this.state.ContractType ? this.state.ContractType.machineName : null}
                    values={jsonData}
                    systemInserted={this.state.systemInserted}
                    onChange={state => {
                        this.setState({extraJsonData: JSON.stringify(state)})
                    }}/>

                <FormDashline/>

                <div style={{border: '3px dashed #CCCCCC', padding: '20px'}}>
                    <NotesEditor notes={this.state.Notes} onChange={notes => {
                        this.setState({Notes: notes})
                    }} name={"Contracts"} id={this.state.id} />
                </div>

                {can('Contracts.setVoidCauses') && <ContractVoidCauseSelect value={this.state.VoidCauses}
                                                                            onChange={se => {
                                                                                this.setState({VoidCauses: se})
                                                                            }}
                                                                            direction={FormInputContainer.HORIZONTAL}/>}
                {(!can('Contracts.setVoidCauses') && this.state.VoidCauses) && <> <p>Sotto stato contratto:</p>
                    <p>   {this.state.VoidCauses.map(item => {
                        return item.name
                    }).join(', ')}
                    </p></>}

                {can('Contracts.setNoReportProduction') && <FormInputRadio label="Non visible nei report produzione" checked={this.state.noReportProduction}
                                                                           direction={FormInputContainer.HORIZONTAL}
                                                                           onClick={se => {
                                                                               this.setState({
                                                                                   noReportProduction: !this.state.noReportProduction,
                                                                               })
                                                                           }}
                />}
                <FormDashline/>

                <label>Allegati</label>
                <FileUploadInput onChange={filesId => {
                    this.setState({filesId: filesId})
                }}
                                 filesId={this.state.filesId}/>

                <FormDashline/>

                {can('Contracts.selectMarketingUsers') && <ContractRoles ContractTypeId={this.state.ContractTypeId}
                                                                         value={{ContractRoles: this.state.ContractRoles}}
                                                                         onChange={se => {
                                                                             this.setState({ContractRoles: se.ContractRoles})
                                                                         }}/>}

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             saveEnable={this.state.isTp2 ? !this.state.telephoneWarningTp2 : true }
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}


class ContractTp2TelephoneViewer extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            telephoneWarningTp2: false
        };
    }

    checkNumber(){
        if (this.props.number && this.props.number.length > 0) {
            return fetch('api', 'TelephonesWhitelist', 'check', {number: this.props.number}).then(data => {
                this.setState({telephoneWarningTp2: !data.body.status, loading: false});
            });
        }

        this.setState({telephoneWarningTp2: true, loading: false});
    }

    componentDidMount() {
        this.checkNumber()
    }

    componentDidUpdate(prev) {
        if (this.props.number !== prev.number) {
          this.checkNumber();
        }

    }

    render(){
        return this.state.loading || !this.state.telephoneWarningTp2 ? <></> : <b style={{color:'red'}}><i className={"fa fa-warning"}></i> TP2</b>;
    }

}

class ContractRolesViewer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            defaultRoles: this.props.defaultRoles
        };
    }

    componentDidMount() {


    }

    resolveRole(role) {


        let roleId = role.ContractTypeRoleId;

        let foundRole = ''

        this.state.defaultRoles.map((dr) => {

            if (dr.id == roleId) {
                foundRole = dr.name;
                return;
            }

        });

        return foundRole;

    }


    render() {


        const rolesJson = this.props.contract.ContractRoles_json;

        if (rolesJson && rolesJson.length > 0) {

            let rolesParsed = JSON.parse(rolesJson);

            let roles = [];

            rolesParsed.map((r) => {
                roles.push(<div>{this.resolveRole(r)} : {r.User.name} {r.User.surname}</div>);
            });

            return roles;

        }

        return null;


    }


}

class ContractRoles extends React.Component {

    constructor(props) {
        super(props);

        this.state = this.props.value;

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadRoles();
    }

    componentDidUpdate(prevPros, prevState) {
        if (prevPros.ContractTypeId != this.props.ContractTypeId)
            this.loadRoles();

        if (JSON.stringify(this.props.value) !== JSON.stringify(prevPros.value))
            this.setState({...this.props.value})

        if (JSON.stringify(this.state) !== JSON.stringify(prevState))
            this.props.onChange(this.state);
    }

    loadRoles() {
        fetch('api', 'ContractTypeRoles', 'select',
            {filters: {ContractTypeId: this.props.ContractTypeId}}).then(msg => {
            setTimeout(() => {
                this.setState({roles: msg.body});
            }, 2000);
        });
    }

    onChange(inputRole, se) {

        if (!se) {
            return this.setState({ContractRoles: undefined});
        }

        let existings = [];
        if (this.state.ContractRoles)
            existings = this.state.ContractRoles;

        let found = false;

        let roles = existings.map(role => {
            if (role.ContractTypeRoleId == inputRole.id) {
                role.UserId = se.id;
                role.User = se;
                found = true;
            }

            return role;
        });

        if (!found) {
            roles.push({
                User: se,
                UserId: se.id,
                ContractTypeRoleId: inputRole.id,
            });
        }

        this.setState({ContractRoles: roles});
    }

    getValue(inputRole) {

        if (!this.state.ContractRoles)
            return null;

        let found = null;

        this.state.ContractRoles.map(role => {
            if (role.ContractTypeRoleId == inputRole.id) {
                found = role.User;
            }

            return role;
        });

        return found;
    }

    render() {
        if (!this.state.roles)
            return null;

        return <React.Fragment>
            {this.state.roles.map(role => {
                return <UserSelect onChange={se => {
                    this.onChange(role, se)
                }}
                                   value={this.getValue(role)}
                                   label={role.name}
                                   direction={FormInputContainer.HORIZONTAL}/>
            })}
        </React.Fragment>
    }
}

class ContractDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.reloadData();
    }

    reloadData() {
        this.setState({loading: true});

        fetch('api', 'Contracts', 'get', this.props.id).then(data => {
            this.setState({...data.body, loading: false});
        })
    }

    render() {
        if (this.state.loading) return <Loader/>


        let extra = {};

        if(this.state.type==='practice') {

            extra = JSON.parse(this.state.extraJsonData);

        }

        return <div className="row">
            <div className="col-sm-12">
                <dl className="dl-horizontal">

                    {this.state.type==='contract' && <>
                    <dt>Codice univoco</dt>
                    <dd>{this.state.uniqueUuid}</dd>


                    <dt>Canale</dt>
                    <dd>{this.state.ChannelId > 0 ? this.state.Channel.name : '-'}</dd>

                    <dt>Punto vendita</dt>
                    <dd>{this.state.SellingPointId > 0 ? this.state.SellingPoint.name : '-'}</dd>

                    <dt>Prodotti</dt>
                    <dd>{this.state.Products.length > 0 ? this.state.Products.map(p => {
                        return p.name
                    }).join(', ') : '-'}</dd>

                    <dt>Modulistica contratto</dt>
                    <dd>{this.state.ContractTypeId > 0 ? this.state.ContractType.name : '-'}</dd>

                    </>}

                    {this.state.type==='practice' && <>

                        <dt>Codice Operazione</dt>
                        <dd>{extra.codoper}</dd>

                        <dt>Tipo Operazione</dt>
                        <dd>{getOperationLabel(extra.operation)}</dd>

                        <dt>Tipologia Cliente</dt>
                        <dd>{extra.customerType}</dd>

                    </>}

                    <dt>Note</dt>
                    <dd>{this.state.note}</dd>

                </dl>
            </div>
        </div>;
    }
}

class ContractTypeRoleSelect extends React.Component {
    render() {
        return <ApiMultiSelect label="Ruolo compensi" {...this.props} model="ContractTypeRoles"
                               formatRow={row => {
                                   return `${row.ContractType.name} / ${row.name}`;
                               }}
        />;
    }
}

function ContractServiceType(props) {
    return <FormInputSelect label="Tipo servizio"
                            direction={FormInputContainer.HORIZONTAL}
                            values={{
                                'single': 'Singolo',
                                'multiEle': 'Multi ELE',
                                'dual': 'Dual',
                            }} {...props} />;
}

class ContractReportMonthly extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterMonth: (moment().month() + 1) + '',
            filterYear: moment().year() + '',
            settingModal: false,
            enelMonthObj: 0,
            enelxMonthObj: 0,
        };

        this.updateData();

    }

    updateData() {

        this.setState({
            loading: true,
            data: false
        });

        fetch('api', 'ContractsExploded', 'reportMonthContract', this.state).then(msg => {
            this.setState({
                loading: false,
                data: msg.body,
                settings: msg.body.settings
            });

            if (!this.state.enelMonthObj) {
                this.setState({
                    enelMonthObj: msg.body.enelMonthObj,
                    enelxMonthObj: msg.body.enelxMonthObj,
                });
            }
        });
    }

    kw(key) {
        try {

            let res = '';

            if (this.state.settings[key].showKw) {
                res += this.fetchResult(key, 'kw');
            }

            if (this.state.settings[key].showM3) {
                res += this.fetchResult(key, 'm3');
            }

            return res;

        } catch (e) {
        }

        return '';
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterYear != this.state.filterYear) {
            this.updateData();
        }

        if (prevState.enelMonthObj != this.state.enelMonthObj ||
            prevState.enelxMonthObj != this.state.enelxMonthObj) {
            if (prevState.enelMonthObj != 0 && prevState.enelxMonthObj != 0)
                this.updateData();
        }

        if (this.state.settingModal.key)
            if (!this.state.settings[this.state.settingModal.key])
                this.setState({
                    settings: {
                        ...this.state.settings,
                        [this.state.settingModal.key]: {}
                    }
                })
    }

    getAmountOfWeekDaysInMonth(date, weekday) {
        date.date(1);
        var dif = (7 + (weekday - date.weekday())) % 7 + 1;
        console.log("weekday: " + weekday + ", FirstOfMonth: " + date.weekday() + ", dif: " + dif);
        return Math.floor((date.daysInMonth() - dif) / 7) + 1;
    }

    getDaysLeft(date) {
        if (date.month() != moment().month() || date.year() != moment().year())
            return 0;

        let left = 0;

        for (let d = date.date() + 1; d <= date.endOf('month').date(); d++) {
            if (date.date(d).weekday() == 5 || date.date(d).weekday() == 6)
                continue;
            left++;
        }

        return left;
    }

    settingModalGet(keyName, defaultValue = undefined) {
        return this.state.settings[this.state.settingModal.key] ?
            this.state.settings[this.state.settingModal.key][keyName] : defaultValue
    }

    settingModalSet(keyName, value) {
        this.setState({
            settings: {
                ...this.state.settings,
                [this.state.settingModal.key]: {
                    ...this.state.settings[this.state.settingModal.key],
                    [keyName]: value,
                }
            }
        });
    }

    fetchResult(key, type = 'tot') {

        if (this.state.loading)
            return '-';

        try {
            if (type == 'tot') {
                return this.state.data[key].tot;
            }

            if (type == 'kw') {
                if (this.state.data[key].kw)
                    return `( ${this.state.data[key].kw} Kw )`;
            }

            if (type == 'm3') {
                if (this.state.data[key].m3)
                    return `( M3: ${this.state.data[key].m3} )`;
            }
        } catch (e) {
        }

        if (type == 'tot')
            return 0;

        if (type == 'kw')
            return '( 0 Kw )';

        if (type == 'm3')
            return '( M3: 0 )';


        return '';
    }

    getIdsUrl(key) {
        if (this.state.data[key])
            return `/contracts_list?cacheIds=${this.state.data[key].uuid}`;
        return "#";
    }

    render() {

        if (!this.state.settings)
            return <Loader/>;

        const enxCH = this.fetchResult('enxCH');
        const enxINL = this.fetchResult('enxINL');
        const enxKO = this.fetchResult('enxKO');
        const enxBDC = this.fetchResult('enxBDC');
        const enxIMF = this.fetchResult('enxIMF');

        const ageBC = this.fetchResult('ageBC');
        const ageBDC = this.fetchResult('ageBDC');
        const ageNe = this.fetchResult('ageNe');
        const ageANN = this.fetchResult('ageANN');
        const ageDVD = this.fetchResult('ageDVD');

        const ageINC = this.fetchResult('ageINC');
        const ageATS = this.fetchResult('ageATS');
        const ageATT = this.fetchResult('ageATT');
        const ageATR = this.fetchResult('ageATR');

        const peBC = this.fetchResult('peBC');
        const peBZ = this.fetchResult('peBZ');
        const peBDC = this.fetchResult('peBDC');
        const peATT = this.fetchResult('peATT');
        const peDRV = this.fetchResult('peDRV');
        const peANN = this.fetchResult('peANN');
        const peATR = this.fetchResult('peATR');
        const peISF = this.fetchResult('peISF');

        const busBCG = this.fetchResult('busBCG');
        const busBCL = this.fetchResult('busBCL');
        const busBDC = this.fetchResult('busBDC');
        const busBZ = this.fetchResult('busBZ');

        const busINC = this.fetchResult('busINC');
        const busMCL = this.fetchResult('busMCL');
        const busMCG = this.fetchResult('busMCG');
        const busANNG = this.fetchResult('busANNG');
        const busANNL = this.fetchResult('busANNL');
        const busIAP = this.fetchResult('busIAP');

        const totANN = this.fetchResult('totANN');

        const nocBDC = this.fetchResult('nocBDC');
        const nocATT = this.fetchResult('nocATT');
        const nocINC = this.fetchResult('nocINC');
        const nocATP = this.fetchResult('nocATP');

        const nocAPQ = this.fetchResult('nocAPQ');
        const nocATR = this.fetchResult('nocATR');
        const nocDVA = this.fetchResult('nocDVA');
        const nocDRV = this.fetchResult('nocDRV');

        const dealerBC = this.fetchResult( 'dealerBC');
        const dealerBZ = this.fetchResult( 'dealerBZ');
        const dealerBDC = this.fetchResult('dealerBDC');
        const dealerANN = this.fetchResult('dealerANN');
        const dealerINC = this.fetchResult('dealerINC');
        const dealerIAP = this.fetchResult('dealerIAP');
        const dealerIAR = this.fetchResult('dealerIAR');

        const winbackBC = this.fetchResult( 'winbackBC');
        const winbackBZ = this.fetchResult( 'winbackBZ');
        const winbackBDC = this.fetchResult('winbackBDC');
        const winbackANN = this.fetchResult('winbackANN');
        const winbackINC = this.fetchResult('winbackINC');
        const winbackIAP = this.fetchResult('winbackIAP');
        const winbackIAR = this.fetchResult('winbackIAR');

        const fiAgenBC = this.fetchResult('fiAgenBC');
        const fiAgenBZ = this.fetchResult('fiAgenBZ');
        const fiAgenBDC = this.fetchResult('fiAgenBDC');
        const fiAgenANN = this.fetchResult('fiAgenANN');
        const fiAgenINC = this.fetchResult('fiAgenINC');
        const fiAgenIAP = this.fetchResult('fiAgenIAP');
        const fiAgenIAR = this.fetchResult('fiAgenIAR');

        const fibraBDC = this.fetchResult('fibraBDC');
        const fibraBCA = this.fetchResult('fibraBCA');
        const fibraDCO = this.fetchResult('fibraDCO');
        const fibraBOZ = this.fetchResult('fibraBOZ');
        const fibraINC = this.fetchResult('fibraINC');
        const fibraCDR = this.fetchResult('fibraCDR');
        const fibraANN = this.fetchResult('fibraCDR');

        const totBLG = this.fetchResult('totBLG');
        const totBLGF = this.fetchResult('totBLGF');
        const totBZZ = this.fetchResult('totBZZ');
        const totBDC = this.fetchResult('totBDC');

        let dd = this.getDaysLeft(moment().month(this.state.filterMonth - 1).year(this.state.filterYear));

        const monObj = this.state.enelMonthObj;
        const xmonObj = this.state.enelxMonthObj;
        const daysLeft = dd;


        return <React.Fragment>
            <Breadcumbs title="Report mensile" items={{'Report mensile': '/'}}/>

            {this.state.settingModal && <Modal onClose={se => {
                this.updateData();
                this.setState({settingModal: false})
            }}>
                <h3>{this.state.settingModal.name}</h3>
                <Row>
                    <div className="col-md-12">

                        <FormInputRadio label="Senza canale" checked={this.settingModalGet('noChannel', false)}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {
                                            this.settingModalSet('noChannel',
                                                this.settingModalGet('noChannel', false) ? false : true)
                                        }}/>

                        {!this.settingModalGet('noChannel', false) && <ChannelSelect isMulti value={
                            this.state.settings[this.state.settingModal.key] ?
                                this.state.settings[this.state.settingModal.key].channel : undefined}

                                                                                     onChange={se => {
                                                                                         this.setState({
                                                                                             settings: {
                                                                                                 ...this.state.settings,
                                                                                                 [this.state.settingModal.key]: {
                                                                                                     ...this.state.settings[this.state.settingModal.key],
                                                                                                     channel: se
                                                                                                 }
                                                                                             }
                                                                                         });
                                                                                     }}

                        />}

                        <ContractStateSelect isMulti value={
                            this.state.settings[this.state.settingModal.key] ?
                                this.state.settings[this.state.settingModal.key].states : undefined}

                                             onChange={se => {
                                                 this.setState({
                                                     settings: {
                                                         ...this.state.settings,
                                                         [this.state.settingModal.key]: {
                                                             ...this.state.settings[this.state.settingModal.key],
                                                             states: se
                                                         }
                                                     }
                                                 });
                                             }}

                                             direction={FormInputContainer.HORIZONTAL}
                        />

                        <ProductSelect isMulti value={
                            this.state.settings[this.state.settingModal.key] ?
                                this.state.settings[this.state.settingModal.key].products : undefined}
                                       hideCategories={true}
                                       onChange={se => {
                                           this.setState({
                                               settings: {
                                                   ...this.state.settings,
                                                   [this.state.settingModal.key]: {
                                                       ...this.state.settings[this.state.settingModal.key],
                                                       products: se
                                                   }
                                               }
                                           });
                                       }}

                                       direction={FormInputContainer.HORIZONTAL}/>

                        <FormInputRadio label="Visualizza Kw" checked={this.settingModalGet('showKw', false)}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {
                                            this.settingModalSet('showKw',
                                                this.settingModalGet('showKw', false) ? false : true)
                                        }}/>

                        <FormInputRadio label="Visualizza M3" checked={this.settingModalGet('showM3', false)}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => {
                                            this.settingModalSet('showM3',
                                                this.settingModalGet('showM3', false) ? false : true)
                                        }}/>

                        <FormInputSelect values={{
                            'both': 'Tutti',
                            'ele+gas': 'Elettrico e Gas',
                            'ele': 'Elettrico',
                            'gas': 'Gas',
                            'fibra': 'Fibra',
                        }} label="Oggetto"
                                         direction={FormInputContainer.HORIZONTAL}
                                         value={
                                             this.state.settings[this.state.settingModal.key] ?
                                                 this.state.settings[this.state.settingModal.key].eleGas : undefined}

                                         onChange={se => {
                                             this.setState({
                                                 settings: {
                                                     ...this.state.settings,
                                                     [this.state.settingModal.key]: {
                                                         ...this.state.settings[this.state.settingModal.key],
                                                         eleGas: se
                                                     }
                                                 }
                                             });
                                         }}/>
                    </div>
                </Row>
            </Modal>}
            <div className="wrapper wrapper-content  animated fadeInRight">
                <Row>

                    <div className="col-md-12 m-3">
                        <Row>
                            <span className="label label-secondary">
                                Dati aggiornati al {moment(this.state.cacheDate).format('YYYY-MM-DD HH:mm')}
                            </span>
                        </Row>
                    </div>

                    <div className="col-md-12">
                        <BoxContainer title="Filtri">
                            <Row>

                                <div className="col-lg-2">
                                    <FormInputSelect value={this.state.filterMonth}
                                                     direction={FormInputContainer.HORIZONTAL}
                                                     label="Mese riferimento"
                                                     values={{
                                                         '1': 'Gennaio',
                                                         '2': 'Febbraio',
                                                         '3': 'Marzo',
                                                         '4': 'Aprile',
                                                         '5': 'Maggio',
                                                         '6': 'Giugno',
                                                         '7': 'Luglio',
                                                         '8': 'Agosto',
                                                         '9': 'Settembre',
                                                         '10': 'Ottobre',
                                                         '11': 'Novembre',
                                                         '12': 'Dicembre',
                                                     }}
                                                     onChange={se => this.setState({filterMonth: se})}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputYear value={this.state.filterYear}
                                                   onChange={se => this.setState({filterYear: se})}/>
                                </div>

                            </Row>
                        </BoxContainer>
                    </div>
                    <div className="col-md-12">
                        <BoxContainer title="Senza Canale">
                            <Row>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-primary">
                    {nocBDC}</span> Buoni da caricare {this.kw('nocBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Buoni da caricare',
                                                                key: 'nocBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-danger">
                    {nocATT}</span> MAI CARICATO (Ripensamento cliente) {this.kw('nocATT')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocATT')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Ripensamento',
                                                                key: 'nocATT'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-danger">
                    {nocINC}</span> MAI CARICATO (Cliente già attivo in EE) {this.kw('nocINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Cliente attivo EE',
                                                                key: 'nocINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-danger">
                    {nocATP}</span> MAI CARICATO (Modulistica errata) {this.kw('nocATP')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocATP')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Modulistica errata',
                                                                key: 'nocATP'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {nocDRV}</span> Inserito al sistema {this.kw('nocDRV')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocDRV')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Inserito al sistema',
                                                                key: 'nocDRV'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {nocAPQ}</span> In attesa pre-quality {this.kw('nocAPQ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocAPQ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / In attesa pre-quality',
                                                                key: 'nocAPQ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {nocATR}</span> POD duplicati {this.kw('nocATR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocATR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / POD duplicati',
                                                                key: 'nocATR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-3">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {nocDVA}</span> Irreperibili {this.kw('nocDVA')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('nocDVA')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Senza Canale / Irreperibili',
                                                                key: 'nocDVA'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>


                            </Row>
                        </BoxContainer>
                    </div>
                    <div className="col-md-5">
                        <BoxContainer title="Agenzia">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">

                                        <li className="list-group-item fist-item">
                    <span class="label label-primary">
                    {ageBC}</span> Buoni caricati {this.kw('ageBC')}

                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageBC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>

                                            <button className="btn  btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Buoni caricati',
                                                                key: 'ageBC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span class="label label-warning">
                    {ageNe}</span> Neve {this.kw('ageNe')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageNe')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Neve',
                                                                key: 'ageNe'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-success">
                    {ageBDC}</span> Buoni da caricare {this.kw('ageBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Buoni da caricare',
                                                                key: 'ageBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span className="label label-danger">
                    {ageANN}</span> Annullati {this.kw('ageANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Annullati',
                                                                key: 'ageANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span className="label label-warning">
                    {ageDVD}</span> Da validare {this.kw('ageDVD')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageDVD')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Da validare',
                                                                key: 'ageDVD'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-info">
                    {ageINC}</span> Incompleti {this.kw('ageINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / Incompleti',
                                                                key: 'ageINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                    <span class="label label-info">
                    {ageATS}</span> In attesa di scansione {this.kw('ageATS')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageATS')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / In attesa di scansione',
                                                                key: 'ageATS'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-info">
                    {ageATT}</span> In attesa di pratiche {this.kw('ageATT')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageATT')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / In attesa di pratiche',
                                                                key: 'ageATT'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-info">
                    {ageATR}</span> In attesa risoluzione {this.kw('ageATR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('ageATR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Agenzia / In attessa risoluzione',
                                                                key: 'ageATR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>

                        </BoxContainer>
                    </div>

                    <div className="col-md-5">
                        <BoxContainer title="SEP">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                  <span class="label label-primary">
                    {peBC}</span> Buoni caricati {this.kw('peBC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peBC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Buoni caricati',
                                                                key: 'peBC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span class="label label-warning">
                    {peBZ}</span> Bozze {this.kw('peBZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peBZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Bozza',
                                                                key: 'peBZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span class="label label-success">
                    {peBDC}</span> Buoni da caricare {this.kw('peBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Buoni da caricare',
                                                                key: 'peBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span className="label label-warning">
                    {peISF}</span> Inviata senza flag {this.kw('peISF')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peISF')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Inviata senza flag',
                                                                key: 'peISF'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                  <span class="label label-info">
                    {peATT}</span> In attesa di pratiche {this.kw('peATT')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peATT')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / In attesa di pratiche',
                                                                key: 'peATT'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span class="label label-info">
                    {peDRV}</span> Criticità da risolvere {this.kw('peDRV')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peDRV')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Criticità da risolvere',
                                                                key: 'peDRV'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span class="label label-danger">
                    {peANN}</span> Annullati {this.kw('peANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Annullati',
                                                                key: 'peANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                  <span class="label label-info">
                    {peATR}</span> Incompleti {this.kw('peATR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('peATR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'SEP / Incompleti',
                                                                key: 'peATR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>

                        </BoxContainer>
                    </div>

                    <div className="col-md-2">
                        <BoxContainer title="Totali">
                            <Row>
                                <div className="col-md-12">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                                            <span className="label label-primary">
                    {totBLG}</span> Buoni Luce Gas {this.kw('totBLG')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('totBLG')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Totali / Buoni Luce Gas',
                                                                key: 'totBLG'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                                            <span className="label label-primary">
                    {totBLGF}</span> Buoni Luce Gas Fibra {this.kw('totBLGF')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('totBLGF')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Totali / Buoni Luce Gas Fibra',
                                                                key: 'totBLGF'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                                            
                                            <span class="label label-warning">{ageNe + peBZ + peISF + ageDVD}</span> Bozze
                                        </li>
                                        <li className="list-group-item fist-item">
                                            <span class="label label-success">{ageBDC + peBDC}</span> Buoni da caricare
                                        </li>
                                    </ul>
                                </div>
                            </Row>

                        </BoxContainer>
                    </div>

                </Row>

                <BoxContainer>

                    <Row>
                        <div className="col-md-12">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th> OBIETTIVO MESE</th>
                                    <th>BUONI DISPONIBILI</th>
                                    <th>DI CUI BOZZE GIA' INSERITE A SISTEMA</th>
                                    <th>DIFFERENZA PER RAGGIUNGIMENTO OBIETTIVO</th>
                                    <th>GIORNI LAVORATIVI DISPONIBILI</th>
                                    <th>MEDIA PRODUZIONE GIORNALIERA (SAB DOM NO)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>ENEL</td>
                                    <td><FormInputText value={monObj}
                                                       onChange={se => {
                                                           this.setState({enelMonthObj: se.target.value})
                                                       }}
                                                       direction={FormInputContainer.HORIZONTAL}/>
                                    </td>
                                    <td>{ageBC + ageBDC + ageNe + peBZ + peBC + peBDC + peISF}</td>
                                    <td>{ageNe + peBZ}</td>
                                    <td>{monObj - (ageBC + ageBDC + ageNe + peBZ + peBC + peBDC)}</td>
                                    <td>{daysLeft}</td>
                                    <td>{((monObj - (ageBC + ageBDC + ageNe + peBZ + peBC + peBDC)) / daysLeft).toFixed(1)}</td>
                                </tr>
                                {/*<tr>
                <td>SENZA BOZZE</td>
                <td>{monObj}</td>
                <td>{ageBC+ageBDC+peBC+peBDC}</td>
                <td>{ageNe+peBZ}</td>
                <td>{monObj-(ageBC+ageBDC+peBC+peBDC)}</td>
                <td>{daysLeft}</td>
                <td>{((monObj-(ageBC+ageBDC+peBC+peBDC))/daysLeft).toFixed(1)}</td>
              </tr>*/}
                                </tbody>
                            </table>

                            <table className="table">
                                <thead>
                                <tr>
                                    <th>TOTALI BUONI INSERITI</th>
                                    <th>TOTALI BUONI IN ATTESA DI DISPOSIZIONI</th>
                                    <th>ANNULLATI</th>
                                    <th>TOTALE LORDO</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{ageBC + ageBDC + ageNe + peBZ + peBC + peBDC}</td>
                                    <td>{ageATS + ageATT + peATT + peDRV}</td>
                                    <td>{totANN}
                                        <button className="btn btn-xs pull-right"
                                                onClick={se => {
                                                    this.setState({
                                                        settingModal: {
                                                            name: 'Totale / Annullati',
                                                            key: 'totANN'
                                                        }
                                                    })
                                                }}><i className="fa fa-gear"></i></button>
                                    </td>
                                    <td>{ageBC + ageBDC + ageNe + peBZ + peBC + peBDC + ageINC + peDRV + ageATS + ageATT + peATT + totANN}</td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                    </Row>

                </BoxContainer>

                <Row>
                    <div className="col-md-6">
                        <BoxContainer title="Fibra agenzia">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {fiAgenBC}</span> Buoni caricati {this.kw('fiAgenBC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenBC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / Buoni caricati',
                                                                key: 'fiAgenBC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {fiAgenINC}</span> Incompleti {this.kw('fiAgenINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / Incompleti',
                                                                key: 'fiAgenINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fiAgenBZ}</span> Bozza {this.kw('fiAgenBZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenBZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / Bozza',
                                                                key: 'fiAgenBZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fiAgenIAP}</span> In attesa di pratiche {this.kw('fiAgenIAP')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenIAP')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / In attesa di pratiche',
                                                                key: 'fiAgenIAP'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {fiAgenBDC}</span> Buoni da caricare {this.kw('fiAgenBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / Buoni da caricare',
                                                                key: 'fiAgenBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fiAgenIAR}</span> In attesa risoluzione {this.kw('fiAgenIAR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenIAR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / In attesa risoluzione',
                                                                key: 'fiAgenIAR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {fiAgenANN}</span> Annullati {this.kw('fiAgenANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fiAgenANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra agenzia / Annullati',
                                                                key: 'fiAgenANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </BoxContainer>
                    </div>
                    <div className="col-md-6">
                        <BoxContainer title="Fibra SEP">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {fibraBCA}</span> Buoni caricati {this.kw('fibraBCA')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraBCA')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Buoni caricati',
                                                                key: 'fibraBCA'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fibraBOZ}</span> Bozze {this.kw('fibraBOZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraBOZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Bozze',
                                                                key: 'fibraBOZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {fibraBDC}</span> Buoni da caricare {this.kw('fibraBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Buoni da caricare',
                                                                key: 'fibraBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fibraINC}</span> Incompleti {this.kw('fibraINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Incompleti',
                                                                key: 'fibraINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {fibraCDR}</span> Criticita da risolvere {this.kw('fibraCDR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraCDR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Criticita da risolvere',
                                                                key: 'fibraCDR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {fibraANN}</span> Annullati {this.kw('fibraANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('fibraANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Fibra / Annullati',
                                                                key: 'fibraANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>


                            </Row>
                        </BoxContainer>
                    </div>

                    <div className="col-md-6">
                        <BoxContainer title="Dealer">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {dealerBC}</span> Buoni caricati {this.kw('dealerBC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerBC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / Buoni caricati',
                                                                key: 'dealerBC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {dealerINC}</span> Incompleti {this.kw('dealerINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / Incompleti',
                                                                key: 'dealerINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {dealerBZ}</span> Bozza {this.kw('dealerBZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerBZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / Bozza',
                                                                key: 'dealerBZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {dealerIAP}</span> In attesa di pratiche {this.kw('dealerIAP')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerIAP')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / In attesa di pratiche',
                                                                key: 'dealerIAP'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {dealerBDC}</span> Buoni da caricare {this.kw('dealerBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / Buoni da caricare',
                                                                key: 'dealerBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {dealerIAR}</span> In attesa risoluzione {this.kw('dealerIAR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerIAR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / In attesa risoluzione',
                                                                key: 'dealerIAR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {dealerANN}</span> Annullati {this.kw('dealerANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('dealerANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Dealer / Annullati',
                                                                key: 'dealerANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </BoxContainer>
                    </div>

                    <div className="col-md-6">
                        <BoxContainer title="Winback">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {winbackBC}</span> Buoni caricati {this.kw('winbackBC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackBC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / Buoni caricati',
                                                                key: 'winbackBC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {winbackINC}</span> Incompleti {this.kw('winbackINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / Incompleti',
                                                                key: 'winbackINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {winbackBZ}</span> Bozza {this.kw('winbackBZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackBZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / Bozza',
                                                                key: 'winbackBZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {winbackIAP}</span> In attesa di pratiche {this.kw('winbackIAP')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackIAP')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / In attesa di pratiche',
                                                                key: 'winbackIAP'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-info">
                    {winbackBDC}</span> Buoni da caricare {this.kw('winbackBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / Buoni da caricare',
                                                                key: 'winbackBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {winbackIAR}</span> In attesa risoluzione {this.kw('winbackIAR')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackIAR')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / In attesa risoluzione',
                                                                key: 'winbackIAR'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {winbackANN}</span> Annullati {this.kw('winbackANN')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('winbackANN')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'WinBack / Annullati',
                                                                key: 'winbackANN'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>
                        </BoxContainer>
                    </div>
                </Row>
                <Row>
                    <div className="col-md-6">
                        <BoxContainer title="Azienda Business">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">

                                        <li className="list-group-item fist-item">
                    <span class="label label-primary">
                    {busBCG}</span> Buoni caricati GAS {this.kw('busBCG')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busBCG')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Buoni caricati GAS',
                                                                key: 'busBCG'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">
                    <span className="label label-primary">
                    {busBCL}</span> Buoni caricati LUCE {this.kw('busBCL')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busBCL')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Buoni caricati LUCE',
                                                                key: 'busBCL'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span class="label label-warning">
                    {busBZ}</span> Bozze {this.kw('busBZ')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busBZ')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Bozze',
                                                                key: 'busBZ'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-success">
                    {busBDC}</span> Buoni da caricare {this.kw('busBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Buoni da caricare',
                                                                key: 'busBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {busANNG}</span> Annullati Gas {this.kw('busANNG')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busANNG')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Annullati Gas',
                                                                key: 'busANNG'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">
                    <span className="label label-danger">
                    {busANNL}</span> Annullati Luce {this.kw('busANNL')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busANNL')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Annullati Luce',
                                                                key: 'busANNL'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>

                                <div className="col-md-6">
                                    <ul className="list-group clear-list">
                                        <li className="list-group-item fist-item">
                    <span class="label label-info">
                    {busINC}</span> Incompleti {this.kw('busINC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busINC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Incompleti',
                                                                key: 'busINC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">
                    <span class="label label-danger">
                    {busMCL}</span> Mai caricato LUCE {this.kw('busMCL')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busMCL')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Mai caricato LUCE',
                                                                key: 'busMCL'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-danger">
                    {busMCG}</span> Mai caricato GAS {this.kw('busMCG')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busMCG')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / Mai caricato GAS',
                                                                key: 'busMCG'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span className="label label-primary">
                    {busIAP}</span> In attesa di pratiche {this.kw('busIAP')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('busIAP')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Azienda Business / In attesa di pratiche',
                                                                key: 'busIAP'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                            </Row>

                        </BoxContainer>
                    </div>

                    <div className="col-md-6">
                        <BoxContainer title="Enel X">
                            <Row>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">

                                        <li className="list-group-item fist-item">
                    <span class="label label-primary">
                    {enxCH}</span> Chiusi {this.kw('enxCH')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('enxCH')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Enel X / Chiusi',
                                                                key: 'enxCH'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>

                                        <li className="list-group-item fist-item">

                  <span class="label label-warning">
                    {enxINL}</span> In Lavorazione {this.kw('enxINL')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('enxINL')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Enel X / In Lavorazione',
                                                                key: 'enxINL'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                        <li className="list-group-item fist-item">

                  <span class="label label-danger">
                    {enxKO}</span> KO {this.kw('enxKO')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('enxKO')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Enel X / KO',
                                                                key: 'enxKO'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6">
                                    <ul className="list-group clear-list">

                                        <li className="list-group-item fist-item">
                    <span class="label label-success">
                    {enxBDC}</span> Buoni da Caricare {this.kw('enxBDC')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('enxBDC')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Enel X / Buoni da Caricare',
                                                                key: 'enxBDC'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>

                                    <ul className="list-group clear-list">

                                        <li className="list-group-item fist-item">
                    <span className="label label-warning">
                    {enxIMF}</span> Incompleto manca fornitura {this.kw('enxIMF')}
                                            <a className="btn btn-xs pull-right" href={this.getIdsUrl('enxIMF')}
                                               target="_blak"><i className="fa fa-eye"></i></a>
                                            <button className="btn btn-xs pull-right"
                                                    onClick={se => {
                                                        this.setState({
                                                            settingModal: {
                                                                name: 'Enel X / Incompleto manca fornitura',
                                                                key: 'enxIMF'
                                                            }
                                                        })
                                                    }}><i className="fa fa-gear"></i></button>
                                        </li>
                                    </ul>

                                </div>

                            </Row>

                        </BoxContainer>
                    </div>

                    <div className="col-md-12">
                        <BoxContainer>

                            <Row>
                                <div className="col-md-12">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th> OBIETTIVO MESE</th>
                                            <th>BUONI DISPONIBILI</th>
                                            <th>DIFFERENZA PER RAGGIUNGIMENTO OBIETTIVO</th>
                                            <th>GIORNI LAVORATIVI DISPONIBILI</th>
                                            <th>MEDIA PRODUZIONE GIORNALIERA (SAB DOM NO)</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>ENEL X</td>
                                            <td>{<FormInputText value={xmonObj}
                                                                onChange={se => {
                                                                    this.setState({enelxMonthObj: se.target.value})
                                                                }}
                                                                direction={FormInputContainer.HORIZONTAL}/>}</td>
                                            <td>{enxCH + enxINL}</td>
                                            <td>{xmonObj - (enxCH + enxINL)}</td>
                                            <td>{daysLeft}</td>
                                            <td>{((xmonObj - (enxCH + enxINL)) / daysLeft).toFixed(1)}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </Row>

                        </BoxContainer>
                    </div>

                </Row>


            </div>
        </React.Fragment>;
    }
}


class ContractReportSellingPoints extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterMonth: (moment().month() + 1) + '',
            filterYear: moment().year() + '',
            filterChannel: 0,
            filterContractType: 0,
        };
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        this.setState({data: false});
        if (this.req)
            this.req.abort();

        this.req = fetch('api', 'Contracts', 'reportSellingPoints', this.state);
        this.req.then(msg => {
            this.setState({data: msg.body});
        }).catch(e => {
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterYear != this.state.filterYear ||
            prevState.filterChannel != this.state.filterChannel ||
            prevState.filterContractType != this.state.filterContractType ||
            prevState.filterProducts != this.state.filterProducts ||
            prevState.filterStateType != this.state.filterStateType) {
            this.updateData();
        }
    }

    render() {
        let barCharData = [];
        let barChartKeys = [];

        let yearTotal = 0;

        let twelve = [...new Array(12).keys()];

        if (this.state.data) {

            yearTotal = this.state.data.reduce((acc, value) => {
                return acc + (twelve.reduce((ack, k) => {
                    return ack + parseInt(value['m' + k]);
                }));
            }, 0);
        }

        const m1 = moment().month(this.state.filterMonth - 1).year(this.state.filterYear)
            .format('MM/YYYY');

        const m2 = moment(moment().month(this.state.filterMonth - 1).year(this.state.filterYear))
            .subtract(1, 'month').format('MM/YYYY');

        if (this.state.data) {
            barCharData = this.state.data.map(row => {

                    const k_current = `m${moment().month() + 1}`;
                    const k_prev = `m${moment().subtract(1, 'month').month() + 1}`;

                    return {"pv": row.name, [m1]: parseInt(row[k_current]), [m2]: parseInt(row[k_prev]), color: '#2f4050'};
                }
            );

            barChartKeys = [m1, m2];
        }

        return <React.Fragment>
            <Breadcumbs title="Report punti vendita" items={{'Home': '/'}}/>
            <div className="wrapper wrapper-content  animated fadeInRight">

                <Row>
                    <div className={"col-md-12 btn-group"} style={{marginBottom: '20px'}}>
                        {can('Contracts.reportBusiness')
                        && <Link className={"btn btn-default"} to="/business_report">Reale</Link>}

                        {can('Products.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_products">Prodotti</Link>}

                        {can('Users.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_users">Utenti</Link>}

                        {can('SellingPoints.reportBusiness') &&
                        <Link className={"btn btn-primary"} to="/contracts_report_selling_points">Punti vendita</Link>}
                    </div>
                </Row>


                <BoxContainer>

                    <Row>
                        <div className={"col-md-12"}>
                            <div className="alert alert-primary" role="alert">
                                Dati live
                            </div>
                        </div>
                    </Row>

                    {true && <Row>

                        <div className="col-lg-2">
                            <FormInputYear value={this.state.filterYear}
                                           onChange={se => this.setState({filterYear: se})}/>
                        </div>

                        <div className="col-lg-2">
                            <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                                             direction={FormInputContainer.HORIZONTAL}
                                             name="filterStateType"
                                             values={{
                                                 '': 'Tutti',
                                                 'success': 'Positivo',
                                                 'warning': 'Neutro',
                                                 'error': 'Negativo',
                                             }}
                                             onChange={se => {
                                                 this.setState({filterStateType: se})
                                             }}/>
                        </div>

                        <div className="col-lg-4">
                            <ProductSelect onChange={se => {
                                this.setState({filterProducts: se});
                            }} direction={FormInputContainer.HORIZONTAL}
                                           hideCategories={true}
                                           isMulti
                                           value={this.state.filterProducts}/>
                        </div>

                        <div className="col-lg-4">
                            <ChannelSelect value={this.state.filterChannel}
                                           onChange={se => {
                                               this.setState({filterChannel: se})
                                           }}
                                           isMulti/>
                        </div>

                        <div className="col-lg-4">
                            <ContractTypesSelect value={this.state.filterContractType}
                                                 onChange={se => {
                                                     this.setState({filterContractType: se})
                                                 }}
                                                 isMulti/>
                        </div>

                    </Row>}


                    <div className="row">

                        {!this.state.data && <Loader/>}

                        {this.state.data && <React.Fragment>

                            <div className={"col-md-12"} style={{height: '600px', width: '100%'}}>
                                <ResponsiveBar
                                    data={barCharData}
                                    keys={barChartKeys}
                                    indexBy="pv"
                                    groupMode={"grouped"}
                                    enableLabel={false}
                                    margin={{top: 80, right: 20, bottom: 180, left: 40}}
                                    padding={0.3}
                                    colors={(id, data) => {
                                        console.log('aaa', id);
                                        return id.id == m1 ? '#2f4050' : '#1ab394'
                                    }}
                                    legends={[]}
                                    animate={true}
                                    axisBottom={{
                                        tickRotation: -45
                                    }}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                            </div>

                            <table className="table table-hover table-mail">
                                <thead>
                                <tr>
                                    <th>Risorsa</th>

                                    <th>Gen</th>
                                    <th>Feb</th>
                                    <th>Mar</th>
                                    <th>Apr</th>
                                    <th>Mag</th>
                                    <th>Giu</th>
                                    <th>Lug</th>
                                    <th>Ago</th>
                                    <th>Set</th>
                                    <th>Ott</th>
                                    <th>Nov</th>
                                    <th>Dic</th>
                                    <th>{this.state.filterYear}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map(row => {
                                    return <tr>
                                        <td>{row.name}</td>

                                        {[...Array(12).keys()].map(m => {
                                            return <td>{row['m' + (m + 1)]}</td>
                                        })}

                                        <td>{[...Array(12).keys()].reduce((acc, m) => {
                                            return acc + parseInt(row['m' + (m + 1)]);
                                        }, 0)}</td>

                                    </tr>;
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Totale</th>
                                    {[...Array(12).keys()].map(m => {
                                        return <th>{
                                            this.state.data.reduce((acc, cur) => {
                                                return acc + parseInt(cur['m' + (m + 1)]);
                                            }, 0)
                                        }</th>
                                    })}
                                    <th>{yearTotal}</th>
                                </tr>
                                </tfoot>
                            </table>

                        </React.Fragment>}
                    </div>
                </BoxContainer>
            </div>


        </React.Fragment>;
    }
}

class ContractReportUsersDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.updateData();
    }

    updateData() {
        this.setState({
            loading: true
        });

        fetch('api', 'Contracts', 'reportUsersDetail', {
            uid: this.props.userId,
            filterYear: this.props.filterYear,
            filterMonth: this.props.filterMonth,
            filterChannel: this.props.filterChannel,
            filterContractType: this.props.filterContractType,
            filterContractRoles: this.props.filterContractRoles,
            filterProducts: this.props.filterProducts,
            filterRole: this.props.filterRole,
            filterStateType: this.props.filterStateType,
            filterGroupBy: this.props.filterGroupBy,
        }).then(msg => {
            this.setState({
                loading: false,
                data: msg.body.data
            });
        });
    }

    componentDidMount() {
        this.updateData();
    }

    render() {

        if (this.state.loading) {
            return <tr>
                <td>
                    <Loader/>
                </td>
            </tr>;
        }

        return <React.Fragment>
            {this.state.data.map(row => {

                return <tr>
                    <td></td>
                    {row.RoleType ?
                        <td><b>{row.RoleName}</b></td> :
                        <td>{row.RoleName}</td>}

                    {[...Array(this.props.filterGroupBy == 'day' ? 31 : 12).keys()].map(m => {
                        return <td>{row['m' + (m)] ? row['m' + (m)] : 0}</td>
                    })}

                    <td>{[...Array(this.props.filterGroupBy == 'day' ? 31 : 12).keys()].reduce((acc, m) => {
                        return acc + parseInt(row['m' + (m)] ? row['m' + (m)] : 0);
                    }, 0)}</td>

                </tr>;
            })}
        </React.Fragment>;
    }
}

class ContractReportUsers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterMonth: (moment().month()) + '',
            filterYear: moment().year() + '',
            filterChannel: 0,
            filterContractType: 0,
            filterGroupBy: 'month',
            openedDetails: []
        };
    }

    toggleDetail(rowId) {

        let openedDetails = this.state.openedDetails;

        if (openedDetails.indexOf(rowId) != -1) {
            openedDetails.splice(openedDetails.indexOf(rowId), 1);
        } else {
            openedDetails.push(rowId);
        }

        this.setState({
            openedDetails: openedDetails
        });


    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        this.setState({data: false});
        if (this.req)
            this.req.abort();

        this.req = fetch('api', 'Contracts', 'reportUsers', this.state);
        this.req.then(msg => {
            this.setState({
                data: msg.body.rows,
                yearTotal: msg.body.yearTotal,
                monthsTotal: msg.body.monthsTotal
            });
        }).catch(e => {
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterYear != this.state.filterYear ||
            prevState.filterChannel != this.state.filterChannel ||
            prevState.filterContractType != this.state.filterContractType ||
            prevState.filterContractRoles != this.state.filterContractRoles ||
            prevState.filterProducts != this.state.filterProducts ||
            prevState.filterUsers != this.state.filterUsers ||
            prevState.filterGroupBy != this.state.filterGroupBy ||
            prevState.filterRole != this.state.filterRole ||
            prevState.filterStateType != this.state.filterStateType ||
            prevState.filterUserTags != this.state.filterUserTags) {
            this.updateData();
        }
    }

    render() {

        let yearTotal = 0;

        let twelve = [...new Array(this.state.filterGroupBy == 'day' ? 31 : 12).keys()];

        if (this.state.data) {

            yearTotal = this.state.yearTotal;

        }

        return <React.Fragment>
            <Breadcumbs title="Report utenti" items={{'Report utenti': '/'}}/>
            <div className="wrapper wrapper-content  animated fadeInRight">

                <Row>
                    <div className={"col-md-12 btn-group"} style={{marginBottom: '20px'}}>
                        {can('Contracts.reportBusiness')
                        && <Link className={"btn btn-default"} to="/business_report">Reale</Link>}

                        {can('Products.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_products">Prodotti</Link>}

                        {can('Users.reportBusiness') &&
                        <Link className={"btn btn-primary"} to="/contracts_report_users">Utenti</Link>}

                        {can('SellingPoints.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_selling_points">Punti vendita</Link>}
                    </div>
                </Row>

                <BoxContainer>

                    <Row>
                        <div className={"col-md-12"}>
                            <div className="alert alert-primary" role="alert">
                                Dati live
                            </div>
                        </div>
                    </Row>

                    {true && <Row>

                        <div className={"col-lg-2"}>
                            <FormInputSelect value={this.state.filterGroupBy}
                                             direction={FormInputContainer.HORIZONTAL}
                                             label="Suddivisione"
                                             values={{
                                                 'month': 'Mensile',
                                                 'day': 'Giornaliera',
                                             }}
                                             onChange={se => this.setState({filterGroupBy: se})}/>
                        </div>

                        {this.state.filterGroupBy == 'day' && <div className={"col-lg-3"}>
                            <FormInputMonth value={this.state.filterMonth}
                                            onChange={se => this.setState({filterMonth: se})}/>
                        </div>}

                        <div className="col-lg-2">
                            <FormInputYear value={this.state.filterYear}
                                           onChange={se => this.setState({filterYear: se})}/>
                        </div>

                        <div className={"col-4"}>
                            {can('Users.tags') &&
                            <TagsSelect value={this.state.filterUserTags}
                                        entityName={"Users"} label={"Tag utente"}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onChange={se => this.setState({
                                            filterUserTags: se ? se : []
                                        })}/>
                            }
                        </div>

                        <div className="col-lg-2">
                            <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                                             direction={FormInputContainer.HORIZONTAL}
                                             name="filterStateType"
                                             values={{
                                                 '': 'Tutti',
                                                 'success': 'Positivo',
                                                 'warning': 'Neutro',
                                                 'error': 'Negativo',
                                             }}
                                             onChange={se => {
                                                 this.setState({filterStateType: se})
                                             }}/>
                        </div>

                        <div className="col-lg-4">
                            <ProductSelect onChange={se => {
                                this.setState({filterProducts: se});
                            }} direction={FormInputContainer.HORIZONTAL}
                                           hideCategories={true}
                                           isMulti
                                           value={this.state.filterProducts}/>
                        </div>

                        <div className="col-lg-4">
                            <ChannelSelect value={this.state.filterChannel}
                                           direction={FormInputContainer.HORIZONTAL}
                                           all={true}
                                           isMulti
                                           additionalValues={[{id: -1, Brand: {name: ''}, name: 'Senza canale'}]}
                                           onChange={se => this.setState({filterChannel: se})}/>

                        </div>

                        <div className="col-lg-4">
                            <ContractTypesSelect value={this.state.filterContractType}
                                                 onChange={se => {
                                                     this.setState({filterContractType: se})
                                                 }}
                                                 isMulti/>
                        </div>

                        {can('Contracts.reportUsersAll') && <div className="col-lg-4">
                            <UserSelect value={this.state.filterUsers}
                                        onChange={se => {
                                            this.setState({filterUsers: se})
                                        }}
                                        isMulti/>
                        </div>}

                        { /*<div className="col-lg-6">
            <ContractTypeRoleSelect value={this.state.filterContractRoles}
              direction={FormInputContainer.HORIZONTAL}
              isMulti
              onChange={se=>{this.setState({filterContractRoles: se})}} />
          </div> */}

                    </Row>}


                    <div className="row">

                        {!this.state.data && <Loader/>}

                        {this.state.data && <React.Fragment>

                            <table className="table table-hover table-striped table-mail sticky-header">
                                <thead>
                                <tr>
                                    <th>Risorsa</th>
                                    <th>Ruolo</th>

                                    {this.state.filterGroupBy != 'day' && <React.Fragment>
                                        <th>Gen</th>
                                        <th>Feb</th>
                                        <th>Mar</th>
                                        <th>Apr</th>
                                        <th>Mag</th>
                                        <th>Giu</th>
                                        <th>Lug</th>
                                        <th>Ago</th>
                                        <th>Set</th>
                                        <th>Ott</th>
                                        <th>Nov</th>
                                        <th>Dic</th>
                                        <th>{this.state.filterYear}</th>
                                    </React.Fragment>}

                                    {this.state.filterGroupBy == 'day' && <React.Fragment>
                                        {Array.from(new Array(31).keys()).map(i => {
                                            return <th>{i + 1}</th>;
                                        })}
                                        <th>Totale</th>
                                    </React.Fragment>}


                                </tr>
                                </thead>
                                <tbody>
                                {this.state.data.map(row => {

                                    const opened = this.state.openedDetails.indexOf(row.UserId) != -1;

                                    let monthTotal = twelve.reduce((acc, m) => {
                                        return acc + parseInt(row['m' + (m + 1)]);
                                    }, 0);

                                    /*if (monthTotal == 0)
                                        return null;*/

                                    return <React.Fragment>
                                        <tr onClick={se => {
                                            this.toggleDetail(row.UserId)
                                        }}>
                                            <td>
                                                {opened && <i className={"fa fa-minus"}></i>}
                                                {!opened && <i className={"fa fa-plus"}></i>}
                                                &nbsp; {row.UserName}
                                            </td>

                                            <td>{row.ContractTypeRoleName}</td>

                                            {[...Array(this.state.filterGroupBy == 'day' ? 31 : 12).keys()].map(m => {
                                                return <td>{row['m' + (m)]}</td>
                                            })}

                                            <td>{row.total}</td>
                                        </tr>

                                        {opened &&
                                        <ContractReportUsersDetail
                                            userId={row.UserId}
                                            filterChannel={this.state.filterChannel}
                                            filterContractType={this.state.filterContractType}
                                            filterContractRoles={this.state.filterContractRoles}
                                            filterProducts={this.state.filterProducts}
                                            filterRole={this.state.filterRole}
                                            filterStateType={this.state.filterStateType}
                                            filterGroupBy={this.state.filterGroupBy}
                                            filterYear={this.state.filterYear}
                                            filterMonth={this.state.filterMonth}/>}

                                    </React.Fragment>;
                                })}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th>Totale</th>
                                    <th></th>
                                    {[...Array(this.state.filterGroupBy == 'day' ? 31 : 12).keys()].map(m => {
                                        return <th>{
                                            this.state.monthsTotal['m' + m] ? this.state.monthsTotal['m' + m] : 0
                                        }</th>
                                    })}
                                    <th>{yearTotal}</th>
                                </tr>
                                </tfoot>
                            </table>

                        </React.Fragment>}
                    </div>
                </BoxContainer>
            </div>


        </React.Fragment>;
    }
}


class ReportStates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterMonth: (moment().month()) + '',
            filterYear: moment().year() + '',
            filterChannel: 0,
        };

        this.updateData();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterChannel != this.state.filterChannel ||
            prevState.filterYear != this.state.filterYear) {
            this.updateData();
        }
    }

    updateData() {
        this.setState({loading: true});
        const payload = {
            filterMonth: this.state.filterMonth,
            filterChannel: this.state.filterChannel,
            filterYear: this.state.filterYear,
        };

        fetch('api', 'Contracts', 'reportStates', payload).then(msg => {
            this.setState({
                loading: false,
                ...msg.body
            });
        });
    }

    render() {

        let positiveBarData = [];
        let positiveBarDataKeys = {};
        let positiveTotal = 0;

        let warningBarData = [];
        let warningBarDataKeys = {};
        let warningTotal = 0;

        let errorBarData = [];
        let errorBarDataKeys = {};
        let errorTotal = 0;

        let pieData = [];


        if (this.state.success) {

            positiveBarData = this.state.success.map(item => {
                positiveTotal += item.count;
                positiveBarDataKeys[item.name] = 1;
                return {
                    ...item,
                }
            });

            warningBarData = this.state.warning.map(item => {
                warningTotal += item.count;
                warningBarDataKeys[item.name] = 1;
                return {
                    ...item,
                }
            });

            errorBarData = this.state.error.map(item => {
                errorTotal += item.count;
                errorBarDataKeys[item.name] = 1;
                return {
                    ...item,
                }
            });

            pieData = [{
                id: "Pagabili",
                label: 'Pagabili',
                color: "#1ab394",
                value: this.state.success
                    .map(i => {
                        return i.count
                    })
                    .reduce((a, c) => {
                        return a + c;
                    }, 0)
            }, {
                id: "Neutri",
                label: 'Neutri',
                color: "#FF9730",
                value: this.state.warning
                    .map(i => {
                        return i.count
                    })
                    .reduce((a, c) => {
                        return a + c;
                    }, 0)
            }, {
                id: "Non Pagabili",
                label: 'Non Pagabili',
                color: "#e35f5f",
                value: this.state.error
                    .map(i => {
                        return i.count
                    })
                    .reduce((a, c) => {
                        return a + c;
                    }, 0)
            }]
        }

        const pieDataTotal = pieData.reduce((a, c) => {
            return a + c.value;
        }, 0);

        const defaultPieTemplate = {
            margin: {top: 40, right: 40, bottom: 80, left: 40},
            innerRadius: 0.5,
            cornerRadius: 5,
            enableRadialLabels: false,
            //radialLabel: 'label',
            borderWidth: 1,
            padAngle: 2,
            colors: (id, data) => {
                return id[`color`];
            },
        };

        const defaultBarTemplate = {
            indexBy: "name",
            keys: ["count"],
            margin: {top: 50, right: 50, bottom: 50, left: 50},
            layout: "horizontal",
            height: 600,
            padding: 0.5,
            animate: true,
            colorBy: 'index',
            axisLeft: {
                renderTick: (tick) => {
                    //const theme = useTheme()

                    let text = '-error-';

                    try {
                        const o = JSON.parse(tick.value);

                        text = `${o.name} [ ${o.count} ]`;

                        if (o.Channel) {
                            text = `${o.Channel.name} / ${text}`;

                            if (o.Channel.Brand) {
                                text = `${o.Channel.Brand.name} - ${text}`;
                            }
                        }

                    } catch (e) {

                    }

                    return (
                        <g transform={`translate(${tick.x},${tick.y - 23})`}>
                            <text
                                textAnchor="left"
                                dominantBaseline="middle"
                                style={{
                                    fontSize: 10,
                                }}
                            >
                                {text}
                            </text>
                        </g>
                    );
                }
            },
            borderWidth: 1,
            borderRadius: 3,
            motionStiffness: 90,
            labelSkipWidth: 10,
            motionDamping: 15,
        }

        return <React.Fragment>
            <Breadcumbs title="Report stati" items={{'Report stati': '/'}}/>

            <div className="wrapper wrapper-content  animated fadeInRight">

                <Row>
                    <div className="col-lg-12">
                        <BoxContainer>
                            <Row>
                                <div className="col-lg-2">
                                    <FormInputMonth value={this.state.filterMonth}
                                                    allMonths={true}
                                                    onChange={se => {
                                                        this.setState({filterMonth: se});
                                                    }}/>
                                </div>
                                <div className="col-lg-2">
                                    <FormInputYear value={this.state.filterYear}
                                                   onChange={se => {
                                                       this.setState({filterYear: se});
                                                   }}/>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <ChannelSelect value={this.state.filterChannel}
                                                   isMulti
                                                   onChange={se => {
                                                       this.setState({filterChannel: se});
                                                   }}/>
                                </div>
                            </Row>
                        </BoxContainer>
                    </div>
                </Row>

                {this.state.loading && <Loader/>}
                {!this.state.loading && <React.Fragment>

                    <Row>
                        <div className="col-lg-5 col-md-12">
                            <BoxContainer title={`Resoconto stati ( Totali: ${pieDataTotal} )`}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsivePie
                                            {...defaultPieTemplate}
                                            data={pieData}
                                            height={400}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[{
                                                match: {id: 'warning'},
                                                id: 'lines'
                                            }]}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    translateY: 56,
                                                    itemWidth: 70,
                                                    itemHeight: 18,
                                                    itemTextColor: '#999',
                                                    symbolSize: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <BoxContainer title={`Stati pagabili ( Totali : ${positiveTotal} )`}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultBarTemplate}
                                            data={positiveBarData}
                                            height={(Object.keys(positiveBarDataKeys).length * 55) + 100}
                                            colors={() => '#1ab394'}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                    </Row>

                    <Row>

                        <div className="col-lg-6">
                            <BoxContainer title={`Stati non pagabili ( Totali : ${errorTotal} )`}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultBarTemplate}
                                            data={errorBarData}
                                            height={(Object.keys(errorBarDataKeys).length * 55) + 100}
                                            colors={() => '#e35f5f'}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className="col-lg-6">
                            <BoxContainer title={`Stati neutri ( Totali : ${warningTotal} )`}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultBarTemplate}
                                            height={(Object.keys(warningBarDataKeys).length * 55) + 100}
                                            data={warningBarData}
                                            colors={() => '#FF9730'}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                    </Row>

                </React.Fragment>}
            </div>
        </React.Fragment>;
    }
}

class BusinessReportItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            opened: false
        };

    }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props))
            this.setState({opened: false});
    }

    render() {

        const item = this.props.item;

        let childrenPieData = [];

        if (item.children.values) {

            if (!item.children.values.filter)
                return null;

            const colors = ['#ebab34', '#dceb34', '#34ebd6', '#6534eb', '#77d128'];

            childrenPieData = item.children.values.filter(child => {
                return !child.isAll; // Rimuovo il "Tutto" dal grafico
            }).map((child, i) => {
                return {
                    id: child.label,
                    label: child.label,
                    color: colors[i],
                    value: child.totals.total
                }
            });
        }

        let pieData = [{
            id: "success",
            label: 'Pagabili',
            color: "#1ab394",
            value: item.totals.totalPositive
        }, {
            id: 'idle',
            label: 'Neutri',
            color: "#9C9C9C",
            value: item.totals.totalIdle
        }, {
            id: "error",
            label: 'Non pagabili',
            color: "#e35f5f",
            value: item.totals.total - (item.totals.totalPositive + item.totals.totalIdle)
        }];

        return <React.Fragment>
            <BoxContainer title={item.label}>
                <Row>
                    <div className="col-md-4">
                        <PieChart data={pieData} height={250}
                                  radialLabelsSkipAngle={10}
                                  fill={[{match: {id: 'idle'}, id: 'lines'}]}
                                  legends={[]}
                        />
                    </div>

                    <div className="col-md-4">
                        <ul class="list-group clear-list m-t">
                            <li class="list-group-item">
                                <span class="float-right"> {item.totals.total} / {formatEur(item.totals.totalEur)}</span>
                                Totale contratti:
                            </li>

                            <li class="list-group-item">
                                <span class="float-right">{formatEur(item.totals.totalPositiveEur)}</span>
                                Contratti in stato positivo:
                            </li>

                            <li class="list-group-item">
                                <span class="float-right">{formatEur(item.totals.sellingPoints)}</span>
                                Costo Punti vendita:
                            </li>

                            <li class="list-group-item">
                                <span class="float-right">{formatEur(item.totals.usersCosts)}</span>
                                Costo Utenti:
                            </li>

                            <li class="list-group-item">
                <span class="float-right">
                  <strong>{formatEur(item.totals.totalEur - item.totals.usersCosts - item.totals.sellingPoints)}</strong>
                </span>
                                <strong>Ricavo:</strong>
                            </li>

                        </ul>
                    </div>

                    <div class="col-md-4">
                        <PieChart data={childrenPieData} radialLabelsSkipAngle={10}/>
                    </div>


                    {item.children.values && item.children.values.map(child => {
                        return <div className="col-md-3">
                            <button className={"btn btn-block " + (this.state.opened == child.label ? 'btn-primary' : 'btn-default')}
                                    onClick={se => {
                                        this.setState({opened: child.label})
                                    }}>
                                {child.label} &nbsp;
                                {Math.floor((child.totals.total / item.totals.total) * 100)}%
                            </button>
                        </div>
                    })}

                </Row>

            </BoxContainer>

            {item.children.values && item.children.values.filter(child => {
                return child.label == this.state.opened
            }).map(child => {
                return <BusinessReportItem item={child}/>
            })}
        </React.Fragment>;
    }
}

class BusinessReport extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            loading: true,
            filterMonth: moment().month() + '',
            filterYear: moment().year() + '',
            opened: [],
        };

    }

    updateData() {
        this.setState({loading: true});
        fetch('api', 'Contracts', 'reportBusiness', {
            filterMonth: this.state.filterMonth,
            filterYear: this.state.filterYear
        }).then(msg => {
            this.setState({
                loading: false,
                ...msg.body
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterYear != this.state.filterYear) {
            this.updateData();
        }
    }

    componentDidMount() {
        this.updateData();
    }

    render() {

        return <React.Fragment>
            <Breadcumbs title="Report produzione reale" items={{'Report produzione reale': '/'}}/>
            <div className="wrapper wrapper-content  animated fadeInRight">
                <Row>
                    <div className={"col-md-12 btn-group"} style={{marginBottom: '20px'}}>

                        {can('Contracts.reportBusiness')
                        && <Link className={"btn btn-primary"} to="/business_report">Reale</Link>}

                        {can('Products.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_products">Prodotti</Link>}

                        {can('Users.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_users">Utenti</Link>}

                        {can('SellingPoints.reportBusiness') &&
                        <Link className={"btn btn-default"} to="/contracts_report_selling_points">Punti vendita</Link>}

                    </div>
                </Row>
                <Row>
                    <div className="col-lg-12">
                        <BoxContainer>
                            <Row>
                                <div className="col-lg-2">
                                    <FormInputMonth value={this.state.filterMonth}
                                                    onChange={se => {
                                                        this.setState({filterMonth: se});
                                                    }}/>
                                </div>
                                <div className="col-lg-2">
                                    <FormInputYear value={this.state.filterYear}
                                                   onChange={se => {
                                                       this.setState({filterYear: se});
                                                   }}/>
                                </div>
                            </Row>
                        </BoxContainer>
                    </div>
                </Row>

                {this.state.loading && <Loader/>}

                {!this.state.loading && <React.Fragment>
                    <Row>
                        <div className={"col-md-12"}>
                            <div className="alert alert-primary" role="alert">
                                Dati aggiornati al {moment(this.state.updatedAt).format('HH:mm DD MMMM YYYY')}
                            </div>
                        </div>
                    </Row>
                    <Row>


                        {/*<div className="col-md-6" style={{height: '600px'}}>
                <ResponsiveSankey data={{
                  nodes: this.state.sankeyNodes,
                  links: this.state.sankeyLinks,
                  layout: 'horizontal',
                  labelOrientation: "horizontal"
                }}
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                />
              </div>*/}

                        <div className="col-md-12">

                            <BusinessReportItem item={this.state.raw.values[0]}/>

                        </div>

                    </Row>

                </React.Fragment>}
            </div>
        </React.Fragment>;
    }
}

export {
    ContractTypeRoleSelect, Contracts, NewContractModal, ContractDetailView,
    ContractReportMonthly, ReportStates, BusinessReport, ChannelVerificationImports,
    ImportConfigurationSelect, ContractsImports, ContractReportUsers, ContractReportSellingPoints
};
